import React, { useState, useEffect } from 'react';
import styles from './PostDataDetail.module.sass';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import { showError } from 'utils/showError';
import { adminEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const deleteEndpoint = adminEndpoints.posts.delete;

export default function PostDataDetail({ data }) {

    const [modalVisible, setModalVisible] = useState(false)
    const history = useHistory();

    const deleteApi = async () => {
        try {
            const response = await devscntrAuth.delete(`${deleteEndpoint}${data?.id}/`);
            console.log(response?.data);
            console.log('deleted');
            history.push('/admin/posts/')
        } catch (error) {
            console.log(error);
            showError('Usuwanie nie powiodło się. Spróbuj ponownie później');
        }
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.container_info}>
                    <div className={styles.title}>
                        <span>Tytuł wpisu: </span>{data?.title}
                    </div>
                    <div className={styles.slug}>
                        <span>Slug wpisu: </span>{data?.slug}
                    </div>
                    <div className={styles.post_id}>
                        <span>ID wpisu: </span>{data?.id}
                    </div>
                    <div className={styles.author}>
                        <span>Nazwa autora: </span>{data?.author}
                    </div>
                    <div className={styles.author_id}>
                        <span>ID autora: </span>{data?.author_id}
                    </div>
                    <div className={styles.views}>
                        <span>Ilość unikatowych wyświetleń: </span>{data?.unique_views_count}
                    </div>
                    <div className={styles.comments}>
                        <span>Ilość komentarzy: </span>{data?.comments_count}
                    </div>
                    <div className={styles.likes}>
                        <span>Ilość lików: </span>{data?.likes_count}
                    </div>
                    <div className={styles.dislikes}>
                        <span>Ilość dislików: </span>{data?.dislikes_count}
                    </div>
                </div>

                <div className={styles.container_buttons}>
                    <Link className={styles.show_button} to={`/post/${data?.slug}`}>
                        <Button>Zobacz wpis</Button>
                    </Link>
                    <Link className={styles.edit_button} to={`/edit-post/${data?.id}`}>
                        <Button>Edytuj wpis</Button>
                    </Link>
                    <div className={styles.delete_button}>
                        <Button onClick={() => setModalVisible(true)} >Usuń wpis</Button>
                    </div>
                </div>
            </div>
            <Modal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                outerClassName={styles.modal_container}
            >
                <div className={styles.delete_container}>
                    <div className={styles.delete_info}>
                        Czy na pewno chcesz usunąć ten post?
                    </div>
                    <div className={styles.confirm_delete}>
                        <Button onClick={deleteApi}>Usuń post</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}