import React from 'react';
import styles from './Earning.module.sass';
import cn from 'classnames';
import Placement from '../../../../../components/Placement';
import { Link } from 'react-router-dom';

export default function Earning({ earning, index }) {

    return (
        <Link 
        to={`/zarobki-w-it?&categories=${earning?.category}`}
        className={cn(styles.container)}
        >
            <div className={styles.placement}>
                <Placement placement={index} />
                <span>{earning?.category || 'Brak nazwy'}</span>
            </div>
            <div className={styles.activity}>
                <div className={styles.activity_text}>
                    Wyświetlenia:
                </div>
                <span>{earning?.views || 'Brak wyniku'}</span>
            </div>
        </Link>
    );
}