import React from "react";
import styles from "./Localization.module.sass";
import cn from "classnames";
import Map from "components/Map";

const Localization = ({ locations }) => {
  return (
    locations.length > 0 && (
      <div className={styles.container}>
        <div className={cn("title-yellow", styles.subtitle)}>Lokalizacje</div>
        <ul className={styles.locations_list}>
          {locations.map((item, index) => (
            <li key={index}>
              - {item.street} {item.number}, {item.city} {item.code}
            </li>
          ))}
        </ul>
        {/* <Map className={styles.locations_map} /> */}
      </div>
    )
  );
};

export default Localization;
