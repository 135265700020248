import React, { useState } from 'react';
import styles from './Details.module.sass';
import cn from 'classnames';
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import TextInput from '../../../components/TextInput';
import Checkbox from '../../../components/Checkbox';
import { jobFormat, jobSeniority } from '../../../data/dataArrays';

const Details = ({ data, setData, internship, job }) => {
  const [isCourseFree, setIsCourseFree] = useState(true);
  const [isReflink, setIsReflink] = useState(true);

  const jobSeniorityArray = jobSeniority;

  const handleJobFormatChange = value => {
    setData(prev => ({ ...prev, format: value }));
  };

  const handleJobSeniorityChange = value => {
    setData(prev => ({ ...prev, seniority: value }));
  };

  return (
    <Card
      className={cn(styles.card)}
      title='Szczegóły oferty'
      classTitle='title-red'
    >
      <div className={styles.details}>
        <div className={styles.col}>
          <Dropdown
            label='Forma pracy'
            value={data.format}
            setValue={handleJobFormatChange}
            options={jobFormat}
            classDropdownHead='Forma pracy'
          />
          <Checkbox
            content='Link do aplikacji'
            value={isReflink}
            onChange={e => {
              setIsReflink(e.target.checked);
              setData(prev => ({ ...prev, application_link: '' }));
            }}
          />
          {isReflink && (
            <TextInput
              icon='link'
              placeholder='https://devscntr.com/'
              value={data.application_link}
              onChange={e =>
                setData(prev => ({
                  ...prev,
                  application_link: e.target.value,
                }))
              }
              disabled={!isReflink}
            />
          )}
        </div>
        {!internship && (
          <div className={styles.col}>
            <Dropdown
              label='Doświadczenie'
              value={data.seniority}
              setValue={handleJobSeniorityChange}
              options={jobSeniorityArray}
              classDropdownHead='Forma pracy'
            />
          </div>
        )}
      </div>
      <div className={styles.row}></div>
    </Card>
  );
};

export default Details;
