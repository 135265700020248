import React, { useEffect, useState } from 'react';
import styles from './NewJob.module.sass';
import TooltipGlodal from '../../components/TooltipGlodal';
import Modal from '../../components/Modal';
import Schedule from '../../components/Schedule';
import Icon from '../../components/Icon';
import Panel from 'screens/NewLayouts/Panel';
import axios from 'axios';
import cn from 'classnames';
import Card from '../../components/Card';
import { useHistory } from 'react-router-dom';
import Details from './Details';
import Salary from './Salary';
import Meta from 'components/Meta';

import useAuth from '../../hooks/useAuth';
import devscntrAuth from '../../api/Instance/devscntrAuth';
import { showError } from '../../utils/showError';
import { jobFormat, jobRecruitment, jobSeniority } from '../../data/dataArrays';
import { validateInput } from 'utils/validation';
import Content from 'screens/NewLayouts/Content';
import LocationsInput from 'components/InputBlocks/LocationsInput';
import { accountsEndpoints, jobsEndpoints } from 'api/endpoints';
import TechnologiesInput from './TechnologiesInput';
import useBreakpoints from 'hooks/useBreakpoints';
import LinksInput from 'screens/NewLayouts/LinksInput';
import Button from 'components/Button';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';

const EDITOR_VERSION = 2;

const NewJob = ({ job, internship }) => {
  // Set up axios hook
  const authCtx = useAuth();
  const [errorMsg, setErrorMsg] = useState('');

  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 716],
    ['tiny', 400],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const [noCompanyModalVisible, setNoCompanyModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const [isPostCreated, setIsPostCreated] = useState(false);

  const [title, setTitle] = useState('');
  const [editorState, setEditorState] = useState('');

  const [detailsData, setDetailsData] = useState({
    format: jobFormat[0],
    seniority: jobSeniority[0],
    application_link: '',
    recruitment_format: jobRecruitment[0],
  });

  const [salary, setSalary] = useState([]);

  const [locations, setLocations] = useState([]);
  const [companyLocations, setCompanyLocations] = useState([]);

  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);

  const isValidUrl = url => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  const validateFields = data => {
    // TITLE
    if (!validateInput(data.title, 100, 3, 'Tytuł')) return;

    // DESCRIPTION6
    if (editorState.trim(' ').length < 1) {
      showError('Opis nie może być pusty');
      return false;
    }
    if (editorState.trim(' ').length > 16000) {
      showError('Przekroczono limit znaków. Skróć opis.');
      return false;
    }

    // SALARY
    if (data.salary.length < 1) {
      showError('Musisz dodać przynajmniej jedną opcję wynagrodzenia');
      return false;
    }

    // DROPDOWNS
    if (!jobFormat.includes(detailsData.format)) {
      showError('Wybierz formę pracy');
      return false;
    }
    if (!jobSeniority.includes(detailsData.seniority)) {
      showError('Wybierz doświadczenie');
      return false;
    }

    // LOCATIONS
    if (data.localization.length < 1) {
      showError('Musisz dodać przynajmniej jedną lokalizację firmy');
      return false;
    }

    // TECHNOLOGIES
    if (data.tags.length < 1) {
      showError('Musisz dodać przynajmniej jedną wymaganą technologię');
      return false;
    }

    if (
      !isValidUrl(data?.application_link) &&
      data?.application_link?.length > 0
    ) {
      showError('Podany link nie jest poprawny');
      return false;
    }

    return true;
  };

  //CATEGORY FUNCTION
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name);
  };

  const categoryFunction = item => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item]);
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories(current =>
        current.filter(object => object.name !== item.name)
      );
    }
  };

  const getCompany = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: accountsEndpoints.company.show,
        cancelToken: cancelToken.token,
      });
      if (!response.data.created_at) {
        setNoCompanyModalVisible(true);
      }
      const locations = response.data.locations;
      setCompanyLocations(
        locations?.map(item => ({
          city: item.city,
          code: item.postcode,
          street: item.street,
          number: item.house_number,
        }))
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('cancel get company');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCompany(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const addJobApi = async data => {
    setIsLoading(true);
    try {
      const response = await devscntrAuth.post(jobsEndpoints.create, data, {
        headers: {
          method: 'create',
        },
      });
      setIsPostCreated(true);
      authCtx.getLevel();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const handleTitleChange = event => {
    const val = event.target.value;
    setTitle(val);
  };

  const handleSubmit = () => {
    const preparedLinks = links.map(link => ({
      description: link.title,
      url: link.url,
    }));

    const preparedCategories = categories?.map((item,index)=>({
      name: item?.name,
      id: item?.id,
    }))

    let preparedData = {
      title: title,
      editor_version: EDITOR_VERSION,
      content: editorState,
      format: jobFormat.indexOf(detailsData.format) + 1,
      salary: salary,
      seniority: jobSeniority.indexOf(detailsData.seniority) + 1,
      application_link: detailsData.application_link,
      recruitment_format:
        jobRecruitment.indexOf(detailsData.recruitment_format) + 1,
      tags: tags,
      additional_url: preparedLinks,
      localization: locations,
      meta_title: metaTitle,
      meta_description: metaDescription,
      categories: preparedCategories,
    };

    if (validateFields(preparedData)) {
      addJobApi(preparedData);
    }
  };

  const handleMetaDescriptionChange = event => {
    const val = event.target.value;
    setMetaDescription(val);
  };

  const handleMetaTitleChange = event => {
    const val = event.target.value;
    setMetaTitle(val);
  };

  const clearAllFields = () => {
    setIsPostCreated(false);
    setIsLoading(false);
    setStartDate(new Date());
    setStartTime(new Date());
    setTitle('');
    setEditorState('');
    setMetaTitle('');
    setMetaDescription('');
    setTags([]);
    setLinks([]);
    setLocations([]);
    setSalary([]);
    setCompanyLocations([]);
    setCategories([]);
    setDetailsData({
      format: jobFormat[0],
      seniority: jobSeniority[0],
      application_link: '',
      recruitment_format: jobRecruitment[0],
    });
  };

  return (
    <>
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.fields} ref={x => setStylesRef(x)}>
              {errorMsg.length > 0 && (
                <div className={styles.error}>
                  <Icon name='info' size='24' />
                  {errorMsg}
                </div>
              )}
              <Content
                className={styles.card}
                onTitleChange={handleTitleChange}
                onDescriptionChange={handleEditorStateChange}
                title={title}
                description={editorState}
                editorVersion={EDITOR_VERSION}
              />
              <ChooseCategoryBreadcrumbs
                title='Kategorie'
                itemClicked={categoryFunction}
                loading={false}
                selectedCategories={categories}
              />
              <LinksInput
                links={links}
                setLinks={setLinks}
                className={styles.card}
              />
              <Salary data={salary} setData={setSalary} />
              <Details data={detailsData} setData={setDetailsData} internship={internship} job={job}/>
              <LocationsInput
                locations={locations}
                setLocations={setLocations}
                savedLocations={companyLocations}
              />
              <Meta
                title={metaTitle}
                description={metaDescription}
                onTitleChange={handleMetaTitleChange}
                onDescriptionChange={handleMetaDescriptionChange}
              />
              <TechnologiesInput tags={tags} setTags={setTags} internship={internship} job={job}/>
            </div>
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Oferta została utworzona</p>
            <div className={styles.panel}>
              <button
                className={cn('button', styles.homebtn)}
                onClick={() => internship ? location.push('/internships') : location.push('/jobs')}
              >
                Zobacz wszystkie oferty
              </button>
              <button
                className={cn('button-stroke', styles.newbtn)}
                onClick={() => clearAllFields()}
              >
                Utwórz kolejną ofertę
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <Panel
          setVisiblePreview={setVisiblePreview}
          setVisibleSchedule={setVisibleModal}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}

      <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>

      <Modal
        outerClassName={styles.modal_outer_container}
        visible={noCompanyModalVisible}
        noCloseButton={true}
        onClose={() => console.log(noCompanyModalVisible)}
      >
        <div className={styles.modal_container}>
          {internship && 'Aby dodać ofertę stażu musisz najpierw dodać firmę.'}
          {job && 'Aby dodać ofertę pracy musisz najpierw dodać firmę.'}
          <div className={styles.container}>
            <Button
              onClick={() => location.push('/')}
              classNames={styles.modal_button}
              text={'Strona główna'}
            ></Button>
            <Button
              onClick={() => location.push('/settings/my-company')}
              classNames={styles.modal_button}
              text={'Dodaj firme'}
            ></Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewJob;
