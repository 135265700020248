import React, { useState, useEffect } from 'react';
import styles from './User.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Placement from '../../../../../components/Placement';

export default function User({ user, index }) {

    return (
        <Link
            className={cn(styles.author)}
            to={`/admin/users/${user?.id}`}
        >
            <div className={styles.placement}>
                <Placement placement={index} />
                <span>{user?.user || 'Brak nazwy'}</span>
            </div>
            <div className={styles.activity}>
                <div className={styles.activity_text}>
                    Aktywność:
                </div>
                <span>{user?.score || 'Brak wyniku'}</span>
            </div>
        </Link>
    );
}