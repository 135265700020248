import React from 'react';
import styles from './Placement.module.sass';

export default function Placement({ placement, isCategory}) {

    const colours = ['#FFD700', '#A9A9A9', '#8B4513']

    return (
        <div
            className={styles.placement}
            style={ placement < 3 ? {backgroundColor: colours[placement] } : (isCategory ? {backgroundColor: '#EFEFEF'}: {})
            }
        >
            <div 
            className={styles.text}
            style={isCategory ? {color: 'black'}: {}}
            >
                {placement+1}
            </div>
        </div>
    );
}