import React, { useState, useEffect } from 'react';
import styles from './Posts.module.sass';
import Card from 'components/Card';
import cn from 'classnames';
import { adminEndpoints } from 'api/endpoints';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import PostsGrid from './PostsGrid';
import SkeletonLoaderPosts from './SkeletonLoaderPosts';

const postsEndpoint = adminEndpoints.dashboard.posts_stats

export default function Posts({ }) {

    const authCtx = useAuth();
    const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
    const [postsData, setPostsData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getPostsData = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.request({
                method: 'get',
                url: postsEndpoint,
            });
            setPostsData(response?.data)
        } catch (error) {
            console.log(error);

        }
        setIsLoading(false)
    };

    useEffect(() => {
        getPostsData();
    }, []);

    const reRender = () => {
        getPostsData();
    }

    return (
        <Card
            className={styles.card}
            title='Zarządzaj postami'
            classTitle={cn('title-blue', styles.title)}
        >
            {isLoading ?
                (
                    <SkeletonLoaderPosts cards={10} />
                ) : (
                    <PostsGrid data={postsData} reRender={reRender} />
                )}
        </Card>
    );
}