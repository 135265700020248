import JobsMap from "components/JobsMap";
import React from "react";
import { useParams } from "react-router";
import JobOffer from "../JobOffer";
import JobOffers from "../JobOffers";

const Jobs = ({jobs, internships}) => {
  const { id } = useParams();
  return (
    <>
      {id !== undefined ? <JobOffer jobs={jobs} internships={internships}/> : <JobOffers jobs={jobs} internships={internships} />}
      <JobsMap />
    </>
  );
};

export default Jobs;
