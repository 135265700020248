import styles from './Earnings.module.sass';
import cn from 'classnames';
import Earning from './Earning';

export default function Earnings({ earnings }) {
    return (
        <div className={cn(styles.earnings)}>
            {earnings?.map((item, index) => (
                <div key={index}>
                    <Earning earning={item} index={index}/>
                </div>
            ))}
        </div>
    );
}