import React, { useState, useEffect } from 'react';
import styles from './Courses.module.sass';
import Card from 'components/Card';
import cn from 'classnames';
import { adminEndpoints } from 'api/endpoints';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import CoursesGrid from './CoursesGrid';
import SkeletonLoaderCourses from './SkeletonLoaderCourses';

const coursesEndpoint = adminEndpoints.dashboard.courses_stats

export default function Courses({ }) {

    const authCtx = useAuth();
    const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
    const [coursesData, setCoursesData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getCoursesData = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.request({
                method: 'get',
                url: coursesEndpoint,
            });
            setCoursesData(response?.data)
        } catch (error) {
            console.log(error);

        }
        setIsLoading(false)
    };

    useEffect(() => {
        getCoursesData();
    }, []);

    const reRender = () => {
        getCoursesData();
    }

    return (
        <Card
            className={styles.card}
            title='Zarządzaj kursami'
            classTitle={cn('title-red', styles.title)}
        >
            {isLoading ?
                (
                    <SkeletonLoaderCourses cards={10} />
                ) : (
                    <CoursesGrid data={coursesData} reRender={reRender} />
                )}
        </Card>
    );
}