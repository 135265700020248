import React, { useState } from 'react';
import styles from './TimePeriod.module.sass';
import Card from 'components/Card';
import cn from 'classnames';
import DatePicker from 'screens/Publications/components/DatePicker';

export default function TimePeriod({setStartDate, setEndDate }) {

    const startDateHandler = date => {
        setStartDate(date);
    };

    const endDateHandler = date => {
        setEndDate(date);
    };

    return (
        <>
            <Card
                className={styles.card}
            >
                <div className={styles.container}>
                    <div className={styles.text}>Statystyki dla okresu: </div>
                    <div className={styles.date_container}>
                        <DatePicker
                            className={styles.date_picker}
                            yearRangeStart={2020}
                            value={startDateHandler}
                        />
                        -
                        <DatePicker
                            className={styles.date_picker}
                            yearRangeStart={2020}
                            value={endDateHandler}
                        />
                    </div>
                </div>
            </Card>
        </>
    );
}