import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Profile.module.sass';
import Icon from 'components/Icon';
import FollowUserButton from 'components/FollowUserButton';
import { Link } from 'react-router-dom';
import UserActions from 'components/MiniUser/UserActions';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';
import LevelProgress from 'components/User/LevelProgress';

const UPDATE_SOCIALS = accountsEndpoints.userData.userInfo;

const Profile = ({
  name,
  image,
  userId,
  levelData,
  currentUserId,
  followers = [],
  blocked = false,
  desc,
  descriptionButton,
}) => {
  const [userInfo, setUserInfo] = useState();
  const [socials, setSocials] = useState(0);

  const getAccountInfo = async signal => {
    if (userId) {
      try {
        const response = await devscntrAuth.request({
          method: 'GET',
          url: UPDATE_SOCIALS,
          headers: {
            'user-id': name,
          },
        });
        setUserInfo(response?.data?.user);
      } catch (e) {
        if (!signal?.aborted) {
          console.error(e);
        }
      }
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, [userId]);

  useEffect(() => {
    const userSocials = userInfo;

    if (userSocials) {
      setSocials({
        github_url: userSocials.github_url || null,
        facebook_url: userSocials.facebook_url || null,
        linkedin_url: userSocials.linkedin_url || null,
        twitter_url: userSocials.twitter_url || null,
        youtube_url: userSocials.youtube_url || null,
      });
    }
  }, [userInfo]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img
            src={image ? image : '/images/default-avatar.png'}
            alt='Avatar'
          />
        </div>

        <div className={styles.information}>
          <div className={styles.header}>
            <div className={styles.name_and_followers}>
              <div className={styles.display_name}>{name}</div>
              <div className={styles.followers}>
                {followers?.length || 0} obserwujących
              </div>
            </div>
            <div className={styles.buttons}>
              {currentUserId !== userId ? (
                <>
                  <FollowUserButton
                    classNames={styles.button}
                    userId={userId}
                    refresh={refresh}
                  />
                  <UserActions
                    className={styles.actions}
                    id={userId}
                    blocked={blocked}
                  />
                </>
              ) : (
                <Link
                  to='/settings'
                  className={cn('button-stroke', styles.button)}
                >
                  Ustawienia
                </Link>
              )}
            </div>
          </div>
          <div className={styles.user_description_section}>
            <div className={styles.user_description}>{desc}</div>
            {desc?.length > 0 && (
              <button
                className={styles.user_description_more}
                onClick={() => descriptionButton()}
              >
                <Icon name='arrow-next' size={24} />
              </button>
            )}
          </div>
          {(socials.facebook_url ||
            socials.twitter_url ||
            socials.github_url ||
            socials.linkedin_url ||
            socials.youtube_url) && (
            <div className={styles.socials}>
              {socials.facebook_url &&
                socials.facebook_url !== 'https://www.facebook.com/' && (
                  <a
                    href={socials.facebook_url}
                    target='_blank'
                    className={styles.social}
                    rel='noreferrer'
                  >
                    <Icon name='facebook' size={20} />
                  </a>
                )}
              {socials.twitter_url &&
                socials.twitter_url !== 'https://www.twitter.com/' && (
                  <a
                    href={socials.twitter_url}
                    target='_blank'
                    className={styles.social}
                    rel='noreferrer'
                  >
                    <Icon name='twitter-fill' size={20} />
                  </a>
                )}
              {socials.github_url &&
                socials.github_url !== 'https://github.com/' && (
                  <a
                    href={socials.github_url}
                    target='_blank'
                    className={styles.social}
                    rel='noreferrer'
                  >
                    <Icon name='github' size={20} />
                  </a>
                )}
              {socials.linkedin_url &&
                socials.linkedin_url !== 'https://www.linkedin.com/' && (
                  <a
                    href={socials.linkedin_url}
                    target='_blank'
                    className={styles.social}
                    rel='noreferrer'
                  >
                    <Icon name='linkedin' size={20} />
                  </a>
                )}
              {socials.youtube_url &&
                socials.youtube_url !== 'https://www.youtube.com/@' && (
                  <a
                    href={socials.youtube_url}
                    target='_blank'
                    className={styles.social}
                    rel='noreferrer'
                  >
                    <Icon name='youtube' size={20} />
                  </a>
                )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.badges_levels}>
        <div className={styles.display_name_levels}>Poziom i odznaka</div>

        {levelData && (
          <div className={styles.levels}>
            <div className={styles.level}>{levelData.level || '1'}</div>
            <div className={styles.level_container}>
              <LevelProgress
                classNames={styles.level_bar}
                currentXp={levelData.currentXp || 0}
                min={levelData.thisLevelXp || 0}
                max={levelData.xpToNextLevel || 0}
                level={levelData.level || '1'}
                rankName={levelData.currentRank || null}
              />
            </div>
          </div>
        )}

        {levelData && levelData?.badge && (
          <>
            <div className={styles.badge}>
              <img src={levelData.badge} alt='' />
            </div>
            {/* <button className={styles.badges_button}>
              <div className={styles.badges_text}>Pokaż wszystkie</div>
              <div className={styles.down_arrow}></div>
            </button> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
