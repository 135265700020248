import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Filtering.module.sass';
import Filters from 'components/Filters';
import Dropdown from 'components/Dropdown';
import {
  jobSeniority,
  jobRecruitment,
  jobFormat,
  jobSeniorityLevels,
} from 'data/dataArrays';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import { jobsEndpoints } from 'api/endpoints';

const jobRecruitmentOptions = ['wszystkie', ...jobRecruitment];
//const jobSeniorityOptions = ['wszystkie', ...jobSeniority];
const jobFormatOptions = ['wszystkie', ...jobFormat];

const Filtering = ({ filters, setFilters, internships }) => {
  const [isMounted, setIsMounted] = useState(false);

  const [jobSeniorityOptions, setJobSeniorityOptions] = useState([
    'wszystkie',
    ...jobSeniority,
  ]);

  const [seniority, setSeniority] = useState(filters.seniority);
  const [format, setFormat] = useState(filters.format);
  const [recruitmentFormat, setRecruitmentFormat] = useState(
    filters.recruitmentFormat
  );

  // GET CV --------------------------------------------------------------GET CV

  const getAvailableSeniority = async signal => {
    try {
      const response = await devscntrNoauth.request({
        method: 'get',
        url: `${jobsEndpoints.seniorityLevels}`,
        signal: signal,
      });
      const availableLevels = Object.keys(response.data);
      console.log(availableLevels);
      const availableSeniority = availableLevels.map(
        level => jobSeniority[level - 1]
      );
      setJobSeniorityOptions(['wszystkie', ...availableSeniority]);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getAvailableSeniority(signal);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      setFilters(prev => ({
        ...prev,
        seniority: seniority,
        format: format,
        recruitmentFormat: recruitmentFormat,
      }));
    } else {
      setIsMounted(true);
    }
  }, [seniority, format, recruitmentFormat]);

  useEffect(() => {
    setSeniority(filters.seniority);
    setFormat(filters.format);
    setRecruitmentFormat(filters.recruitmentFormat);
  }, [filters]);

  const handleFiltersClear = () => {
    setFilters({
      seniority: jobSeniorityOptions[0],
      format: jobFormatOptions[0],
      recruitmentFormat: jobRecruitmentOptions[0],
    });
    setSeniority(jobSeniorityOptions[0]);
    setFormat(jobFormatOptions[0]);
    setRecruitmentFormat(jobRecruitmentOptions[0]);
  };

  return (
    <Filters className={styles.filter} title='Filtrowanie'>
      <div className={styles.filter_body}>
        {!internships && (
          <Dropdown
            label='Poziom'
            options={jobSeniorityOptions}
            // options={['wszystkie', ...Object.keys(jobSeniorityLevels)]}
            value={seniority}
            setValue={setSeniority}
          />
        )}
        <Dropdown
          label='Forma pracy'
          options={jobFormatOptions}
          value={format}
          setValue={setFormat}
        />
        {/* <Dropdown
          label="Forma rekrutacji"
          options={jobRecruitmentOptions}
          value={recruitmentFormat}
          setValue={setRecruitmentFormat}
        /> */}

        <button
          className={cn('button', styles.button)}
          onClick={handleFiltersClear}
        >
          Resetuj filtry
        </button>
      </div>
    </Filters>
  );
};
export default Filtering;
