import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './MiniUser.module.sass';

import { Link } from 'react-router-dom';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';
import useBreakpoints from 'hooks/useBreakpoints';
import ModalReport from 'components/ModalReport';
import { accountsEndpoints } from 'api/endpoints';
import UserActions from './UserActions';

const MiniUser = React.forwardRef(
  ({ className, user = null, id, hideRss = true, refresh = () => {} }, ref) => {
    const authCtx = useAuth();
    const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
    const [hidden, setHidden] = useState(false);

    const stylesRef = useRef(null); // element DOM dla resizeObserver-a
    const breakpoints = [
      ['desktop', Infinity],
      ['mobile', 716],
    ];
    useBreakpoints(stylesRef.current, breakpoints);

    const [userData, setUserData] = useState(null);

    const getUserData = async (signal, id = null) => {
      if (!id) return;
      try {
        const response = await axiosInstance.request({
          method: 'get',
          url: accountsEndpoints.userData.userInfo,
          signal: signal,
          headers: {
            'USER-ID': id,
          },
        });
        const data = response.data.user;
        if (data) {
          setUserData({
            id: data?.id,
            username: data?.display_name || 'Brak nazwy',
            image: data?.image || null,
            rss: data?.is_rss || false,
          });
        }
      } catch (e) {
        if (!signal?.aborted) {
          console.error(e);
        }
      }
    };

    useEffect(() => {
      const abortController = new AbortController();
      const { signal } = abortController;

      if (!user && id) getUserData(signal, id);

      return () => {
        abortController.abort();
      };
    }, []);

    useEffect(() => {
      if (user?.is_rss && hideRss) setHidden(true);
      if (!userData && user) {
        setUserData({
          id: user?.id,
          username: user?.display_name || 'Brak nazwy',
          image: user?.image || null,
          rss: user?.is_rss || false,
        });
      }
    }, []);

    return userData && !hidden ? (
      <article ref={stylesRef}>
        <ModalReport visible={false} onClose={() => {}} />
        <div className={cn(styles.item, { [className]: className })} ref={ref}>
          <Link to={`/profile/${userData?.username}`} className={styles.avatar}>
            <img
              src={userData?.image || '/images/default-avatar.png'}
              alt='Avatar'
            />
          </Link>

          <section className={styles.details}>
            <Link
              to={`/profile/${userData?.username}`}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <h2 className={styles.title}>
                {userData?.username || 'Brak nazwy'}
              </h2>
            </Link>
            <UserActions
              className={styles.actions}
              id={userData.id}
              refresh={refresh}
              hide={() => setHidden(true)}
            />
          </section>
        </div>
      </article>
    ) : null;
  }
);

export default MiniUser;
