import React, { useState, useEffect } from 'react';
import styles from './CoursesGrid.module.sass';
import ItemGrid from './ItemGrid';
import Pagination from 'components/Pagination';

export default function CoursesGrid({ data, reRender }) {

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    return (
        <>
            <div className={styles.header_container}>
                <div className={styles.header_title}>
                    Tytuł kursu
                </div>
                <div className={styles.header_author}>
                    Autor kursu
                </div>
                <div className={styles.header_views}>
                    Wyświetlenia
                </div>
                <div className={styles.header_reactions}>
                    Reakcje
                </div>
            </div>
            <div className={styles.container}>
                {data.map((item, index) => (
                    <ItemGrid key={index} item={item} reRender={reRender} />
                ))}
            </div>
            <Pagination
                currentPage={currentPage}
                pageSize={10}
                totalCount={data?.length}
                onPageChange={page => setCurrentPage(page)}
            />
        </>
    );
}