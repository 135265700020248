import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './styles/app.sass';
import Page from './components/Page';
import Home from './screens/Home';
import Pins from './screens/Pins';
import NewPost from './screens/NewPost';
import Courses from './screens/Courses';
import Notification from './screens/Notification';
import Settings from './screens/Settings';
import SignUp from './screens/SignUp';
import SignIn from './screens/SignIn';
import ProfilePage from './screens/ProfilePage';
import Subscriptions from './screens/Subscriptions';
import Logout from './components/Logout';
import AuthContext from './context/AuthContext';
import Post from './screens/Post';
import Course from './screens/Course';
import NewCourse from './screens/NewCourse';
import Search from './screens/Search';
import NewJob from './screens/NewJob';
import ModalMessage from './components/ModalMessage';
import JZWIT from './screens/JakZaczacWIt';
import TechnologyDetail from './screens/JakZaczacWIt/TechnologyDetail';
import Jobs from './screens/Jobs';
import Publications from './screens/Publications';
import NewTechnology from './screens/NewTechnology';
import Admin from 'screens/Admin';
import ModalLogin from 'components/ModalLogin';
import ModalShare from 'components/ModalShare';
import EditJob from 'screens/NewJob/edit';
import EditPost from 'screens/NewPost/edit';
import AcitvateAccount from 'screens/SignUp/ActivateAccount';
import EditCourse from 'screens/NewCourse/edit';
import Support from 'screens/Support';
import Posts from 'screens/Posts';
import ModalReport from 'components/ModalReport';
import PrivacyBanner from 'components/PrivacyBanner';
import Privacy from 'screens/Privacy';
import Statute from 'screens/Statute';
import LoginWithGoogle from 'screens/Login/LoginWithGoogle';
import Dashboard from 'screens/Account/Dashboard';
import Account from 'screens/Account';
import { getEnvName } from 'utils';
import ResetRequest from 'screens/Account/Password/ResetRequest';
import ResetPassword from 'screens/Account/Password/ResetPassword';
import { FormContextProvider } from 'context/FormContext';
import JZWITContext, { JZWITContextProvider } from 'context/jzwitContext';
import EditJZWIT from 'screens/NewTechnology/edit';
import EarningsSection from 'screens/EarningsSection';
import BigFiveAbout from 'screens/BigFiveTest/BigFiveAbout';
import BigFiveTest from 'screens/BigFiveTest/BigFiveTest';
import BigFiveTestResult from 'screens/BigFiveTest/BigFiveTestResult';

function App() {
  // Authorization context
  const authCtx = useContext(AuthContext);
  //const messageCtx = useContext(MessageContext);
  // get isLoggedIn from context (true/false)
  const isLoggedIn = authCtx.isLoggedIn;
  // get user info from context
  const isAuthor = authCtx.isAuthor;
  const isAdmin = authCtx.isAdmin;
  const isSuperuser = authCtx.isSuperuser;
  const jzwitCtx = useContext(JZWITContext);

  const redirectForGuests = '/';

  // Return routes, protected routes (if user is not logged in, redirect to sign-in)
  return (
    <>
      <ModalMessage />
      <Router>
        <PrivacyBanner />
        <ModalLogin />
        <ModalShare />
        <ModalReport />
        <Switch>
          <Route path='/reset-password/:uidb64/:token'>
            {!isLoggedIn && <ResetPassword />}
            {isLoggedIn && <Redirect to='/' />}
          </Route>
          <Route exact path='/account/password/reset'>
            {!isLoggedIn && <ResetRequest />}
            {isLoggedIn && <Redirect to='/' />}
          </Route>
          <Route path='/support'>
            <Page backButton>
              <Support />
            </Page>
          </Route>
          <Route exact path={['/', '/courses']}>
            <Page>
              <Courses />
            </Page>
          </Route>
          <Route exact path='/course/:slug'>
            <Page backButton backPath='/courses'>
              <Course />
            </Page>
          </Route>
          <Route exact path='/posts'>
            <Page>
              <Posts />
            </Page>
          </Route>
          <Route exact path='/post/:slug'>
            <Page backButton backPath='/posts'>
              <Post />
            </Page>
          </Route>
          <Route exact path='/search/:search'>
            <Page backButton>
              <Search />
            </Page>
          </Route>
          <Route exact path='/profile/:id'>
            <Page>
              <ProfilePage />
            </Page>
          </Route>
          <Route exact path='/privacy'>
            <Page backButton>
              <Privacy />
            </Page>
          </Route>
          <Route exact path='/statute'>
            <Page backButton>
              <Statute />
            </Page>
          </Route>
          <Route path='/admin'>
            {isAdmin ? (
              <Page backButton>
                <Admin />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/pins'>
            {isLoggedIn && (
              <Page backButton>
                <Pins />
              </Page>
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>{' '}
          <Route exact path='/'>
            <Page title=''>
              <Courses />
            </Page>
          </Route>
          <Route exact path='/pins/:name'>
            {isLoggedIn && (
              <Page backButton>
                <Pins />
              </Page>
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/subscriptions'>
            {isLoggedIn && (
              <Page backButton>
                <Subscriptions />
              </Page>
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/big-five'>
            <Page>
              <BigFiveAbout />
            </Page>
          </Route>
          <Route exact path='/big-five/test'>
            <Page>
              <BigFiveTest />
            </Page>
          </Route>
          <Route exact path='/big-five/result/:id'>
            <Page>
              <BigFiveTestResult />
            </Page>
          </Route>
          {/* ------------------- JZWIT --------------------- */}
          <Route exact path={['/jak-zaczac-w-it/*', '/jak-zaczac-w-it']}>
            <Page>
              <JZWIT />
            </Page>
          </Route>
          {/* ------------------- ZAROBKI --------------------- */}
          <Route exact path='/zarobki-w-it'>
            <Page>
              <EarningsSection />
            </Page>
          </Route>
          {(getEnvName() !== 'prod' || isAdmin) && (
            <Route exact path={`${jzwitCtx.jzwitPath}/:idTech`}>
              <Page backButton>
                <TechnologyDetail />
              </Page>
            </Route>
          )}
          {(getEnvName() !== 'prod' || isAdmin) && (
            <Route exact path='/new-technology'>
              {isAdmin ? (
                <Page title='Nowa Technologia' backButton newElement={true}>
                  <FormContextProvider>
                    <NewTechnology />
                  </FormContextProvider>
                </Page>
              ) : (
                <Redirect to='/' />
              )}
              {!isLoggedIn && <Redirect to={redirectForGuests} />}
            </Route>
          )}
          {(getEnvName() !== 'prod' || isAdmin) && (
            <Route exact path='/jak-zaczac-w-it-edit/:idTech'>
              {isAdmin ? (
                <Page title='Edytuj technologie' backButton newElement={true}>
                  <FormContextProvider>
                    <EditJZWIT />
                  </FormContextProvider>
                </Page>
              ) : (
                <Redirect to='/' />
              )}
              {!isLoggedIn && <Redirect to={redirectForGuests} />}
            </Route>
          )}
          {/* JZWIT------------------------------------------------------------------------JZWIT*/}
          <Route path='/account'>
            {isLoggedIn && (
              <Page backButton>
                <Account />
              </Page>
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path={['/jobs', '/jobs/:id']}>
            <Page>
              <Jobs jobs={true}/>
            </Page>
          </Route>
          <Route exact path={['/internships', '/internships/:id']}>
            <Page>
              <Jobs internships={true}/>
            </Page>
          </Route>
          <Route exact path='/publications/:id'>
            {isAuthor ? (
              <Page backButton>
                <Publications />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/new-course'>
            {isAuthor ? (
              <Page title='Dodaj nowy kurs' backButton newElement={true}>
                <NewCourse />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/edit-course/:id'>
            {isAuthor ? (
              <Page title='Edytuj kurs' backButton newElement={true}>
                <EditCourse />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/new-job'>
            {isAuthor ? (
              <Page title='Nowa oferta pracy' backButton newElement={true}>
                <NewJob job/>
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/edit-job/:id'>
            {isAuthor ? (
              <Page title='Edytuj ofertę' backButton newElement={true}>
                <EditJob job/>
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/new-internship'>
            {isAuthor ? (
              <Page title='Nowa oferta stażu' backButton newElement={true}>
                <NewJob internship />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/edit-internship/:id'>
            {isAuthor ? (
              <Page title='Edytuj ofertę stażu' backButton newElement={true}>
                <EditJob internship/>
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/new-post'>
            {isAuthor ? (
              <Page title='Nowy Post' backButton newElement={true}>
                <NewPost />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route exact path='/edit-post/:id'>
            {isAuthor ? (
              <Page title='Edytuj post' backButton newElement={true}>
                <EditPost />
              </Page>
            ) : (
              <Redirect to='/' />
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route path='/settings'>
            {isLoggedIn && (
              <Page title='Ustawienia' backButton>
                <Settings />
              </Page>
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route path='/notification'>
            {isLoggedIn && (
              <Page title='Powiadomienia' backButton>
                <Notification />
              </Page>
            )}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route path='/logout'>
            {isLoggedIn && <Logout />}
            {!isLoggedIn && <Redirect to={redirectForGuests} />}
          </Route>
          <Route path='/sign-in'>
            {!isLoggedIn && <SignIn />}
            {isLoggedIn && <Redirect to='/' />}
          </Route>
          <Route exact path='/sign-up'>
            {!isLoggedIn && <SignUp />}
            {isLoggedIn && <Redirect to='/' />}
          </Route>
          <Route exact path='/google/api/'>
            {!isLoggedIn && <LoginWithGoogle />}
            {isLoggedIn && <Redirect to='/' />}
          </Route>
          <Route exact path='/activate-account/:uidb64/:token'>
            <AcitvateAccount />
          </Route>
          {
            // Handle not defined routes
          }
          <Route path='*'>
            <Redirect to='/' />
          </Route>
          {/* <Route exact path="/sign-up" render={() => <SignUp />} />
            <Route exact path="/sign-in" render={() => <SignIn />} />
            <Route exact path="/pagelist" component={PageList} />
            <Route exact path="*" render={() => <Redirect to="/sign-in" />}/> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
