import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './TechnologiesInput.module.sass';
import Card from 'components/Card';
import TextInput from 'components/TextInput';
import Icon from 'components/Icon';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import axios from 'axios';
import { showError } from 'utils/showError';
import { validateInput } from 'utils/validation';
import OutsideClickHandler from 'react-outside-click-handler';
import { tagColors } from 'data/tagColors';
import { jobSeniority } from 'data/dataArrays';
import Dropdown from 'components/Dropdown';
import TechStack from 'screens/JobOffer/TechStack';
import Tag from 'components/Tags/Tag';
import TechStackItem from 'screens/JobOffer/TechStack/Item';
import uuid from 'react-uuid';
import { tagsEndpoints } from 'api/endpoints';

const TechnologiesInput = ({
  className,
  tags,
  setTags,
  maxTagNumber = 8,
  internship,
  job,
}) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [tagInput, setTagInput] = useState('');
  const [tagLevel, setTagLevel] = useState(jobSeniority[0]);
  const [categories, setCategories] = useState([]);
  const [hint, setHint] = useState('');
  const [visibleHint, setVisibleHint] = useState(false);

  const jobSeniorityArray = jobSeniority;

  const compareNumbers = (a, b) => {
    return b.tags_count - a.tags_count;
  };

  const getCategories = async cancelToken => {
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${tagsEndpoints.show}?limit=10000`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      const cats = response?.data?.sort(compareNumbers);
      setCategories(cats);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCategories(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const getHint = value => {
    if (value === '') return '';

    setVisibleHint(true);
    const allHints = categories.filter(item =>
      item?.name?.trim().toLowerCase().includes(value?.trim().toLowerCase())
    );
    console.log(allHints);
    return allHints?.length > 0 ? allHints[0] : '';
  };

  const useHint = () => {
    handleAddTag(hint.name, hint.colour);
    setHint('');
  };

  const handleChange = e => {
    setTagInput(e.target.value);
    setHint(getHint(e.target.value));
  };

  useEffect(() => {
    console.log(hint);
  }, [hint]);

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;

    handleAddTag(
      tagInput,
      tagColors[Math.floor(Math.random() * tagColors.length)]
    );
  };

  const handleAddTag = (
    value,
    colour = tagColors[Math.floor(Math.random() * tagColors.length)]
  ) => {
    if (!value?.length > 0) return;
    if (!validateInput(value.trim().toLowerCase(), 20, 3, 'tag', 2)) {
      return;
    }

    let alreadyExists = false;
    tags.forEach(item => {
      if (item.name === value.trim().toLowerCase()) {
        showError('Podany tag jest już na liście');
        alreadyExists = true;
        return;
      }
    });

    if (value !== '' && tags.length < maxTagNumber && !alreadyExists) {
      setTags([
        ...tags,
        {
          name: value.trim().toLowerCase(),
          colour: colour,
          level: jobSeniority.indexOf(tagLevel) || 0,
        },
      ]);
      setTagInput('');
      console.log(tags);
    }
  };

  const removeTag = index => {
    setTags(tags.filter((tag, i) => i !== index));
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title='Technologie'
      classTitle='title-purple'
      head={`${tags.length}/${maxTagNumber}`}
    >
      <div className={styles.input_container}>
        <input
          placeholder='Podaj nazwę i zatwierdź enterem'
          className={styles.input}
          onKeyDown={handleKeyDown}
          value={tagInput}
          onChange={handleChange}
          onFocus={() => setVisibleHint(true)}
        />
        {!internship && (
          <Dropdown
            className={styles.dropdown}
            value={tagLevel}
            setValue={setTagLevel}
            options={jobSeniorityArray}
            classDropdownHead='Poziom'
          />
        )}
        <button className={cn('button')} onClick={() => handleAddTag(tagInput)}>
          Dodaj
        </button>
      </div>

      {hint.name?.length > 0 && visibleHint && (
        <OutsideClickHandler onOutsideClick={() => setVisibleHint(false)}>
          <Tag
            classNames={styles.hint}
            name={hint.name || 'Brak nazwy'}
            color={hint.colour || '#ffffff'}
            onClick={useHint}
          />
          {/* <button
            className={styles.hint}
            style={{ backgroundColor: hint.colour || '#ffffff' }}
            onClick={useHint}
          >
            {hint.name}
          </button> */}
        </OutsideClickHandler>
      )}

      <div className={styles.container}>
        {/* <TechStack
          className={styles.tech_stack}
          tags={tags}
          headingVisible={false}
        /> */}
        {tags.map((item, index) => (
          <div className={styles.item} key={uuid()}>
            <TechStackItem
              classNames={styles.tech_stack_item}
              name={item?.name || 'Brak nazwy'}
              level={item?.level || 0}
              color={item?.colour || '#ffffff'}
              link={false}
            />
            <div className={styles.remove} onClick={() => removeTag(index)}>
              <Icon name='trash' size={18} />
            </div>
          </div>
          // <div
          //   className={styles.tag}
          //   style={{ backgroundColor: tag.colour || '#ffffff' }}
          //   key={index}
          // >
          //   <span className={styles.text}>
          //     {tag.name} -{' '}
          //     <span className={styles.level}>
          //       {jobSeniority[tag.level] || 'brak'}
          //     </span>
          //   </span>
          //   <div className={styles.remove} onClick={() => removeTag(index)}>
          //     <Icon name='close' size={18} />
          //   </div>
          // </div>
        ))}
      </div>
    </Card>
  );
};
export default TechnologiesInput;
