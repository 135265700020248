import styles from './Users.module.sass';
import cn from 'classnames';
import User from './User';

export default function Users({ users}) {
  return (
    <div className={cn(styles.users)}>
      {users?.map((item, index) => (
        <div key={index}>
          <User user={item} index={index}/>
        </div>
      ))}
    </div>
  );
}
