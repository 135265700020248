import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import styles from './Modal.module.sass';
import Icon from '../Icon';

const Modal = ({
  outerClassName,
  visible,
  onClose,
  children,
  noCloseButton,
}) => {
  const escFunction = useCallback(
    e => {
      if (e.keyCode === 27) {
        onClose && onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector('#modal');
      //disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
    return () => clearAllBodyScrollLocks();
  }, [visible]);

  return createPortal(
    visible && (
      <div id='modal' className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={onClose}>
            {children}
            {!noCloseButton && (
              <button className={styles.close} onClick={onClose}>
                <Icon name='close' size='20' />
              </button>
            )}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
