import React from 'react';
import styles from './AboutMeSkills.module.sass';
import cn from 'classnames';

const AboutMeSkills = ({ skills }) => {
  const maxSkillLevel = 5;

  const levels = {
    1: 'Intern',
    2: 'Junior',
    3: 'Mid',
    4: 'Senior',
    5: 'Expert',
  };

  return (
    <div className={styles.container}>
      <div className={cn('title-blue', styles.header)}>Umiejętności</div>
      {skills?.length > 0 ? (
        <div className={styles.skills_list}>
          {skills?.map((item, index) => (
            <div className={styles.skill_container} key={index}>
              <div className={styles.image}>
                <img
                  src={item?.icon ? item?.icon : '/images/Skills.png'}
                  alt='Skill'
                />
              </div>
              <div className={styles.details}>
                <div className={styles.skill_name}>{item?.name}</div>
                <div className={styles.skill_level}>
                  {item?.level ? levels[item?.level] : 'Brak poziomu'}
                </div>
                <div className={styles.skill_progress}>
                  {[...Array(item?.level)].map((e, index) => (
                    <span className={styles.skill_dot} key={index}></span>
                  ))}
                  {[...Array(maxSkillLevel - item?.level)].map((e, index) => (
                    <span className={styles.skill_dot_empty} key={index}></span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.no_information}>
          Brak informacji o umiejętnościach.
        </div>
      )}
    </div>
  );
};

export default AboutMeSkills;
