import React, { useState } from 'react';
import styles from './BigFiveCareerPathItem.module.sass';
import Breadcrumb from 'components/Breadcrumb';
import classNames from 'classnames';

const BigFiveCareerPathItem = ({ breadcrumb }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className={styles.item}>
      <Breadcrumb className={styles.breadcrumb} items={breadcrumb} />
      {breadcrumb[breadcrumb?.length - 1]?.path_explanation && (
        <>
          <div
            className={classNames(styles.description, {
              [styles.showMore]: showMore,
            })}
          >
            {breadcrumb[breadcrumb?.length - 1].path_explanation}
          </div>
          <button
            className={styles.showMore}
            onClick={() => setShowMore(prev => !prev)}
          >
            {showMore ? 'Ukryj' : 'Pokaż więcej'}
          </button>
        </>
      )}
    </div>
  );
};

export default BigFiveCareerPathItem;
