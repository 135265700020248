import React, { useState } from 'react';
import styles from './MyFollows.module.sass';
import cn from 'classnames';
import Item from '../Item';
import useBreakpoints from 'hooks/useBreakpoints';

const MyFollows = ({ className, data }) => {
  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 716],
  ];
  useBreakpoints(stylesRef, breakpoints);

  return (
    <div
      className={cn(styles.container, { [className]: className })}
      ref={x => setStylesRef(x)}
    >
      {data.length > 0 ? (
        <div className={styles.list}>
          {data.map(user => (
            <Item user={user} key={`user_id_${user.display_name}`} />
          ))}
        </div>
      ) : (
        <p className={styles.info}>
          Ten użytkownik jeszcze nikogo nie zaobserwował.
        </p>
      )}
    </div>
  );
};

export default MyFollows;
