import React from "react";
import cn from "classnames";
import styles from "./ItemUser.module.sass";
import Icon from "../../../Icon";
import { Link } from "react-router-dom";

const links = {
  user: "/profile",
  rss: "/course",
};

const ItemUser = ({ className, item, onClick }) => {
  const username = item.display_name || "Brak nazwy";
  const link = item.is_rss ? links["rss"] : links["user"];

  return (
    <div className={cn(styles.item, className)}>
      <Link to={`${link}/${item.display_name}`} className={styles.link} onClick={onClick}>
        <div className={cn(styles.preview, styles.cover)}>
          <img src={item.image || "/images/default-avatar.png"} alt="Avatar profilu" />
        </div>
        <div className={styles.details}>
          <div className={styles.content}>Profil</div>
          <div className={styles.title}>{username}</div>
        </div>
      </Link>
    </div>
  );
};

export default ItemUser;
