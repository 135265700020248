import React, { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './ProfileData.module.sass';
import Item from '../Item';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import Editor from 'components/Editor';
import AuthContext from 'context/AuthContext';
import PhoneNumberInput from 'components/PhoneNumberInput';
import axios from 'axios';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showError } from 'utils/showError';
import AvatarEditor from 'components/AvatarEditor';
import BackgroundImageEditor from 'components/BackgroundImageEditor';
import { accountsEndpoints } from 'api/endpoints';
import { showSuccess } from 'utils/showSuccess';
import Loader from 'components/Loader';
import SocialInputs from './SocialInputs';
import { validateInput, validateUrl } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { extractSocialLinks } from 'utils';
import Button from 'components/Button';
import EditorTiny from 'components/EditorTiny';
import TextArea from 'components/TextArea';
import EmploymentHistory from './EmploymentHistory';
import useDarkMode from 'use-dark-mode';
import Skills from './Skills';
import Education from './Education';
import Certificates from './Certificates';
import Interests from './Interests';
import ForeignLanguages from './ForeignLanguages';

// API URLs
const GET_ACCOUNT_INFO_URL = accountsEndpoints.userData.myAccount;
const UPDATE_PROFILE_URL = accountsEndpoints.userData.myAccount;
const UPDATE_SOCIALS = accountsEndpoints.userData.userInfo;
const CHANGE_EMAIL_REQUEST_URL = '/accounts/email/change-requests';
const UPDATE_EMAIL_REQUEST_URL = '/accounts/email/update-requests';
const UPDATE_PHONE_URL = '/accounts/phone/change-requests';
const UPDATE_PROFILE_IMG_URL = accountsEndpoints.userData.profileImage;
const UPDATE_BACKGROUND_IMG_URL = accountsEndpoints.userData.backgroundImage;
const UPDATE_PROFILE_BG_IMG_URL = '/accounts/me/background-image-upload';

const ProfileData = ({ className, data, setData, refreshData }) => {
  const authCtx = useContext(AuthContext);
  const bgImgRef = useRef();

  // STATES--------------------------------------------------------------------------------STATES

  const [isLoading, setIsLoading] = useState(true);

  const [profileData, setProfileData] = useState({
    displayName: '',
    firstname: '',
    lastname: '',
    email: '',
  });

  const [employmentHistory, setEmploymentHistory] = useState([]);
  const [employmentRemoveList, setEmploymentRemoveList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skillsRemoveList, setSkillsRemoveList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [interestsRemoveList, setInterestsRemoveList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [languagesRemoveList, setLanguagesRemoveList] = useState([]);
  const [education, setEducation] = useState([]);
  const [educationRemoveList, setEducationRemoveList] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [certificatesRemoveList, setCertificatesRemoveList] = useState([]);

  const [socials, setSocials] = useState([]);
  const [userInfo, setUserInfo] = useState('');

  const [phone, setPhone] = useState('');
  const [agreements, setAgreements] = useState({});

  const [publicEmail, setPublicEmail] = useState('');
  const [publicPhone, setPublicPhone] = useState('');

  // Images, files
  const [profileImg, setProfileImg] = useState(authCtx.userImg || null);
  const [profileBgImg, setProfileBgImg] = useState(null);
  const [file, setFile] = useState(null);
  const [bgFile, setBgFile] = useState(null);

  const [description, setDescription] = useState('');

  const getAccountInfo = async signal => {
    const id = data?.data?.user?.id;
    const displayName = data?.data?.user?.display_name;
    console.log(data?.data)
    if (id) {
      try {
        const response = await devscntrAuth.request({
          method: 'GET',
          url: UPDATE_SOCIALS,
          headers: {
            'user-id': displayName,
          },
        });
        setUserInfo(response?.data?.user);
      } catch (e) {
        if (!signal?.aborted) {
          console.error(e);
        }
      }
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, [data]);

  useEffect(() => {
    const userData = data?.data?.user;
    const userSocials = userInfo;

    if (userSocials) {
      setSocials({
        github_url: userSocials.github_url || '',
        facebook_url: userSocials.facebook_url || '',
        linkedin_url: userSocials.linkedin_url || '',
        twitter_url: userSocials.twitter_url || '',
        youtube_url: userSocials.youtube_url || '',
      });
    }

    if (data) {
      setIsLoading(false);
      // Set profile data
      setProfileData({
        displayName: userData?.display_name || '',
        firstname: userData?.first_name || '',
        lastname: userData?.last_name || '',
        email: userData?.email || '',
      });

      // Set phone data
      setPhone(userData?.phone);
      setPublicPhone(userData?.cv_phone);
      setPublicEmail(userData?.cv_email);

      // Set bio Editor state if user's bio content is not empty

      if (userData?.bio) {
        setDescription(userData?.bio);
      }

      if (userData?.employments) {
        setEmploymentHistory(userData?.employments);
      }

      if (userData?.skills) {
        setSkills(userData?.skills);
      }

      if (userData?.interests) {
        setInterests(userData?.interests);
      }

      if (userData?.foreign_languages) {
        setLanguages(userData?.foreign_languages);
      }

      if (userData?.education) {
        setEducation(userData?.education);
      }

      if (userData?.certificates) {
        setCertificates(userData?.certificates);
      }

      // Set user's agreements
      setAgreements({
        personalDetails: userData?.accept_personal_details || true,
        privacyPolitics: userData?.accept_privacy_politics || true,
      });

      // Set profile image
      setProfileImg(userData?.image);
      // Set profile background image
      setProfileBgImg(userData?.background_image);
    }
  }, [data, userInfo]);

  // STATES--------------------------------------------------------------------------------STATES
  // HANDLERS----------------------------------------------------------------------------HANDLERS

  // Handle bio's Editor changes, set state
  const handleDescriptionChange = e => {
    setDescription(e.target.value);
  };

  // Handle inputs changes | ONLY: Display name, First name, Last name, Bio
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setProfileData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle data submit
  const handleSubmit = e => {
    e.preventDefault();

    // Send profile data to API
    if (validation()) {
      updateProfile();
    }
  };

  // HANDLERS----------------------------------------------------------------------------HANDLERS
  // UPDATE PROFILE----------------------------------------------------------------UPDATE PROFILE

  const prepareData = () => {
    let newData = {
      bio: description,
    };

    if (profileData.displayName.trim().length > 0)
      newData = { ...newData, display_name: profileData.displayName.trim() };
    if (profileData.firstname.trim().length > 0)
      newData = { ...newData, first_name: profileData.firstname.trim() };
    if (profileData.lastname.trim().length > 0)
      newData = { ...newData, last_name: profileData.lastname.trim() };

    newData = { ...newData, phone: phone };
    newData = { ...newData, cv_phone: publicPhone };
    newData = { ...newData, cv_email: publicEmail };

    return newData;
  };

  const prepareInformation = () => {
    let newData = [];
    if (socials?.github_url) {
      newData = { ...newData, github_url: socials.github_url };
    } else {
      newData = { ...newData, github_url: '' };
    }
    if (socials?.facebook_url) {
      newData = { ...newData, facebook_url: socials.facebook_url };
    } else {
      newData = { ...newData, facebook_url: '' };
    }
    if (socials?.twitter_url) {
      newData = { ...newData, twitter_url: socials.twitter_url };
    } else {
      newData = { ...newData, twitter_url: '' };
    }
    if (socials?.linkedin_url) {
      newData = { ...newData, linkedin_url: socials.linkedin_url };
    } else {
      newData = { ...newData, linkedin_url: '' };
    }
    if (socials?.youtube_url) {
      newData = { ...newData, youtube_url: socials.youtube_url };
    } else {
      newData = { ...newData, youtube_url: '' };
    }

    newData = {
      ...newData,
      employments: [...employmentHistory, ...employmentRemoveList],
    };

    newData = { ...newData, skills: [...skills, ...skillsRemoveList] };

    newData = {
      ...newData,
      foreign_languages: [...languages, ...languagesRemoveList],
    };

    newData = { ...newData, interests: [...interests, ...interestsRemoveList] };

    newData = { ...newData, education: [...education, ...educationRemoveList] };

    newData = {
      ...newData,
      certificates: [...certificates, ...certificatesRemoveList],
    };

    newData = {
      ...newData,
      icon: profileImg,
    };

    return newData;
  };

  const updateProfile = async () => {
    setIsLoading(true);

    const preparedData = prepareData();
    const preparedInformation = prepareInformation();

    try {
      const response = await devscntrAuth.put(UPDATE_PROFILE_URL, preparedData);
      showSuccess('Zapisano zmiany pomyślnie');
    } catch (error) {
      console.log(error);
      showError('Wystąpił błąd podczas zapisywania zmian');
    }

    if (
      socials?.github_url ||
      socials?.facebook_url ||
      socials?.twitter_url ||
      socials?.linkedin_url ||
      socials?.youtube_url
    ) {
      try {
        const response = await devscntrAuth.post(
          UPDATE_SOCIALS,
          preparedInformation
        );
        showSuccess('Zapisano zmiany pomyślnie');
      } catch (error) {
        showError(
          'Nie udało się zaktualizować Twoich profili społecznościowych'
        );
      }
    }

    setIsLoading(false);
  };

  // UPDATE PROFILE----------------------------------------------------------------UPDATE PROFILE
  // IMAGEs upload APIs--------------------------------------------------------IMAGEs upload APIs

  const updateProfileImage = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      // Send data to API
      const response = await devscntrAuth.put(
        UPDATE_PROFILE_IMG_URL,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      //setRefresh(prev => !prev);
      authCtx.getLevel();
      showSuccess('Zmieniono zdjęcie profilu');
    } catch (error) {
      showError(
        'Nie udało się zaktualizować zdjęcia profilu. Spróbuj ponownie później.'
      );
    }
  };

  useEffect(() => {
    if (file) updateProfileImage();
  }, [file]);

  useEffect(() => {
    authCtx.changeUserImg(profileImg);
  }, [profileImg]);

  const updateProfileBgImage = async () => {
    const formData = new FormData();
    formData.append('image', bgFile);

    try {
      // Send data to API
      const response = await devscntrAuth.put(
        UPDATE_PROFILE_BG_IMG_URL,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      authCtx.getLevel();
      showSuccess('Zmieniono zdjęcie w tle');
    } catch (error) {
      showError(
        'Nie udało się zaktualizować zdjęcia w tle. Spróbuj ponownie później.'
      );
    }
  };

  useEffect(() => {
    if (bgFile) updateProfileBgImage();
  }, [bgFile]);

  // IMAGEs upload APIs--------------------------------------------------------IMAGEs upload APIs
  // IMAGEs remove APIs--------------------------------------------------------IMAGEs remove APIs

  // Remove profile img
  const removeProfileImgAPI = async () => {
    try {
      // Send data to API
      const response = await devscntrAuth.delete(UPDATE_PROFILE_IMG_URL);
      console.log(response?.data);
      authCtx.changeUserImg(null);
      //showMessage("success", "Usunięto zdjęcie profilu");
      showSuccess('Zdjęcie profilu zostało usunięte');
    } catch (error) {
      showError(
        'Nie udało się usunąć zdjęcia profilu. Spróbuj ponownie później.'
      );
      console.log(error);
    }
  };

  // Remove profile background img
  const removeBackgroundImgAPI = async () => {
    try {
      // Send data to API
      const response = await devscntrAuth.delete(UPDATE_BACKGROUND_IMG_URL);

      setProfileBgImg(null);
      //showMessage("success", "Usunięto zdjęcie w tle");
      showSuccess('Zdjęcie w tle zostało usunięte');
    } catch (error) {
      showError(
        'Nie udało się usunąć zdjęcia w tle. Spróbuj ponownie później.'
      );
      //setBgImgErrMsg("Nie udało się usunąć zdjęcia, spróbuj ponownie później.");
    }
  };

  // IMAGEs remove APIs--------------------------------------------------------IMAGEs remove APIs
  // VALIDATION------------------------------------------------------------------------VALIDATION
  const validation = () => {
    // Profile data validation
    if (profileData.displayName.length > 0) {
      if (
        !validateInput(
          profileData.displayName.trim(),
          validationRules.displayName.maxChars,
          validationRules.displayName.minChars,
          validationRules.displayName.name,
          validationRules.displayName.security
        )
      ) {
        return false;
      }
    } else {
      showError('Nazwa użytkownika nie może być pusta');
      return false;
    }
    if (profileData.firstname.length > 0) {
      if (
        !validateInput(
          profileData.firstname.trim(),
          validationRules.firstname.maxChars,
          validationRules.firstname.minChars,
          validationRules.firstname.name,
          validationRules.firstname.security
        )
      ) {
        return false;
      }
    } else {
      showError('Imię nie może być puste');
      return false;
    }

    if (profileData.lastname.length > 0) {
      if (
        !validateInput(
          profileData.lastname.trim(),
          validationRules.lastname.maxChars,
          validationRules.lastname.minChars,
          validationRules.lastname.name,
          validationRules.lastname.security
        )
      ) {
        return false;
      }
    } else {
      showError('Nazwisko nie może być puste');
      return false;
    }

    if (employmentHistory.length > 0) {
      for (let i = 0; i < employmentHistory.length; i++) {
        if (employmentHistory[i]?.remove !== true) {
          if (!(employmentHistory[i]?.company_name.length > 0)) {
            showError('Nazwa firmy nie może być pusta');
            return false;
          }
          if (!(employmentHistory[i]?.position.length > 0)) {
            showError('Nazwa pozycji nie może być pusta');
            return false;
          }
          if (!(employmentHistory[i]?.description.length > 0)) {
            showError('Opis wykonywanej pracy nie może być pusty');
            return false;
          }
          if (!employmentHistory[i]?.start_date) {
            showError('Data rozpoczęcia pracy nie może być pusta');
            return false;
          }
        }
      }
    }

    if (certificates.length > 0) {
      for (let i = 0; i < certificates.length; i++) {
        if (certificates[i]?.remove !== true) {
          if (!(certificates[i]?.name.length > 0)) {
            showError('Nazwa certyfikatu nie może być pusta');
            return false;
          }
          if (!(certificates[i]?.issuing_organization.length > 0)) {
            showError('Nazwa organizacji wydającej nie może być pusta');
            return false;
          }
          if (!certificates[i]?.issue_date) {
            showError('Data wydania certyfikatu nie może być pusta');
            return false;
          }
          if (!certificates[i]?.credential_id) {
            showError('ID tożsamości certyfikatu nie może być puste');
            return false;
          }
          if (!certificates[i]?.credential_url) {
            showError('URL tożsamości certyfikatu nie może być puste');
            return false;
          }
        }
      }
    }

    if (education.length > 0) {
      for (let i = 0; i < education.length; i++) {
        if (education[i]?.remove !== true) {
          if (!(education[i]?.school.length > 0)) {
            showError('Nazwa szkoły nie może być pusta');
            return false;
          }
          if (!(education[i]?.field.length > 0)) {
            showError('Nazwa dziedziny nie może być pusta');
            return false;
          }
          if (!(education[i]?.major.length > 0)) {
            showError('Nazwa specjalizacji nie może być pusta');
            return false;
          }
          if (!(education[i]?.degree.length > 0)) {
            showError('Stopień nie może być pusty');
            return false;
          }
          if (!(education[i]?.description.length > 0)) {
            showError('Opis studiów nie może być pusty');
            return false;
          }
          if (!education[i]?.start_date) {
            showError('Czas rozpoczęcia nauki nie może być pusty');
            return false;
          }
        }
      }
    }

    // Socials validation
    if (socials?.facebook?.length > 0) {
      if (
        !validateInput(
          socials.facebook.trim(),
          validationRules.displayName.maxChars,
          validationRules.displayName.minChars,
          validationRules.displayName.name,
          validationRules.displayName.security
        )
      )
        return false;
    }
    if (socials?.twitter?.length > 0) {
      if (
        !validateInput(
          socials.twitter.trim(),
          validationRules.displayName.maxChars,
          validationRules.displayName.minChars,
          validationRules.displayName.name,
          validationRules.displayName.security
        )
      )
        return false;
    }
    if (socials?.github?.length > 0) {
      if (
        !validateInput(
          socials.github.trim(),
          validationRules.displayName.maxChars,
          validationRules.displayName.minChars,
          validationRules.displayName.name,
          validationRules.displayName.security
        )
      )
        return false;
    }
    if (socials?.linkedin?.length > 0) {
      if (
        !validateInput(
          socials.linkedin.trim(),
          validationRules.displayName.maxChars,
          validationRules.displayName.minChars,
          validationRules.displayName.name,
          validationRules.displayName.security
        )
      )
        return false;
    }

    if (socials?.youtube?.length > 0) {
      if (
        !validateInput(
          socials.youtube.trim(),
          validationRules.displayName.maxChars,
          validationRules.displayName.minChars,
          validationRules.displayName.name,
          validationRules.displayName.security
        )
      )
        return false;
    }

    return true;
  };

  // VALIDATION------------------------------------------------------------------------VALIDATION

  const handleUploadClick = () => {
    bgImgRef.current.click();
  };

  const handleRemoveClick = () => {
    removeBackgroundImgAPI();
  };

  return (
    <div className={styles.container}>
      <Item
        className={cn(className)}
        title='Dane profilu'
        classTitle={cn('title-green')}
      >
        <div className={styles.images}>
          <AvatarEditor
            userId={authCtx.userId}
            image={authCtx.userImg}
            setImage={setProfileImg}
            setFile={setFile}
            onDelete={removeProfileImgAPI}
          />
          <BackgroundImageEditor
            className={styles.background_image}
            userId={authCtx.userId}
            image={profileBgImg}
            setImage={setProfileBgImg}
            setFile={setBgFile}
            onDelete={removeBackgroundImgAPI}
          />
        </div>

        <div className={styles.fieldset}>
          <TextInput
            className={styles.field}
            label='Nazwa użytkownika'
            name='displayName'
            type='text'
            value={profileData.displayName}
            onChange={handleChange}
            required
          />
          <TextInput
            className={styles.field}
            label='Imię'
            name='firstname'
            type='text'
            value={profileData.firstname}
            onChange={handleChange}
            required
          />
          <TextInput
            className={styles.field}
            label='Nazwisko'
            name='lastname'
            type='text'
            value={profileData.lastname}
            onChange={handleChange}
            required
          />
          <TextInput
            className={styles.field}
            label='Prywatny email'
            name='email'
            type='email'
            value={profileData.email}
            onChange={handleChange}
            disabled
            required
          />
          <TextInput
            className={styles.field}
            label='Prywatny numer telefonu'
            name='phone'
            type='text'
            value={phone}
            onChange={setPhone}
            disabled
          />
          <TextInput
            className={styles.field}
            label='Publiczny email'
            name='email'
            type='email'
            value={publicEmail}
            onChange={(e) => setPublicEmail(e.target.value)}
          />
          <TextInput
            className={styles.field}
            label='Publiczny numer telefonu'
            name='phone'
            type='text'
            value={publicPhone}
            onChange={(e) => setPublicPhone(e.target.value)}
          />
          <SocialInputs data={socials} setData={setSocials} />
        </div>
      </Item>

      <Item
        className={cn(styles.about_me_container)}
        title='O mnie'
        classTitle={cn('title-blue')}
      >
        <TextArea
          className={styles.text_area}
          value={description}
          onTitleChange={handleDescriptionChange}
          maxLength={4000}
        />
      </Item>

      <EmploymentHistory
        employmentHistory={employmentHistory}
        setEmploymentHistory={setEmploymentHistory}
        employmentRemoveList={employmentRemoveList}
        setEmploymentRemoveList={setEmploymentRemoveList}
      />

      <Education
        education={education}
        setEducation={setEducation}
        educationRemoveList={educationRemoveList}
        setEducationRemoveList={setEducationRemoveList}
      />

      <Certificates
        certificates={certificates}
        setCertificates={setCertificates}
        setCertificatesRemoveList={setCertificatesRemoveList}
      />

      <Skills
        skills={skills}
        setSkills={setSkills}
        skillsRemoveList={skillsRemoveList}
        setSkillsRemoveList={setSkillsRemoveList}
      />

      <Interests
        interests={interests}
        setInterests={setInterests}
        setInterestsRemoveList={setInterestsRemoveList}
      />

      <ForeignLanguages
        languages={languages}
        setLanguages={setLanguages}
        setLanguagesRemoveList={setLanguagesRemoveList}
      />

      <Button
        classNames={styles.submin_btn}
        onClick={handleSubmit}
        isLoading={isLoading}
      >
        Zapisz zmiany
      </Button>
    </div>
  );
};

export default ProfileData;
