import React, { useState } from 'react';
import styles from './ItemGrid.module.sass';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { showError } from 'utils/showError';
import { adminEndpoints } from 'api/endpoints';
import devscntrAuth from 'api/Instance/devscntrAuth';

const deleteEndpoint = adminEndpoints.posts.delete;

export default function ItemGrid({ item, reRender }) {

    const [modalVisible, setModalVisible] = useState(false)

    const deleteApi = async () => {
        try {
            const response = await devscntrAuth.delete(`${deleteEndpoint}${item?.id}/`);
            console.log(response?.data);
            console.log('deleted');
            reRender && reRender()
        } catch (error) {
            console.log(error);
            showError('Usuwanie nie powiodło się. Spróbuj ponownie później');
        }
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.title_container}>
                    <span className={styles.mobile_text}>
                        Tytuł wpisu :
                    </span>
                    <Link className={styles.title} to={`/post/${item?.slug}`}>
                        {item?.title}
                    </Link>
                </div>
                <div className={styles.author_container}>
                    <div className={styles.mobile_text}>
                        Autor wpisu :
                    </div>
                    <Link className={styles.author} to={`/admin/users/${item?.author_id}`}>
                        {item?.author}
                    </Link>
                </div>
                <div className={styles.views}>
                    <div className={styles.mobile_text}>
                        Wyświetlenia:
                    </div>
                    <Icon name={'eye'} fill={'#6F767E'} size={25} viewBox='0 0 24 24' />
                    {item?.unique_views_count}
                </div>
                <div className={styles.reactions}>
                    <div className={styles.reactions_comments}>
                        <Icon name={'message'} fill={'#6F767E'} size={25} />{item?.comments_count}
                    </div>
                    <div className={styles.reactions_likes}>
                        <Icon name={'thumbs-up-fill'} fill={'#6F767E'} size={25} viewBox='0 0 24 24' />{item?.likes_count}
                    </div>
                    <div className={styles.reactions_dislikes}>
                        <Icon name={'thumbs-down-fill'} fill={'#6F767E'} size={25} viewBox='0 0 24 24' />{item?.dislikes_count}
                    </div>
                </div>

                <div className={styles.options}>
                <Link className={styles.detail_button} to={`/admin/posts/${item?.id}`}>
                        <Icon name='copy' size={25} />
                    </Link>
                    <Link className={styles.edit_button} to={`/edit-post/${item?.id}`}>
                        <Icon name='edit' size={25} />
                    </Link>
                    <button className={styles.trash_button} onClick={() => setModalVisible(true)}>
                        <Icon name='trash' size={25} />
                    </button>
                </div>
            </div>
            <Modal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                outerClassName={styles.modal_container}
            >
                <div className={styles.delete_container}>
                    <div className={styles.delete_info}>
                        Czy na pewno chcesz usunąć post: {item?.title}
                    </div>
                    <div className={styles.confirm_delete}>
                        <Button onClick={deleteApi}>Usuń post</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}