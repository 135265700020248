export const numberWithCommas = x => {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + '%';
};

// jeśli jest większe niż tysiąc, doda literkę i zaokrągli
export const setShortenAmount = value => {
  if (isNaN(value)) return '-';
  if (!value && value !== 0) return '-';
  if (value < 1000) return value;

  const stringNumber = value.toString().replace(/[^0-9.]/g, '');
  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];

  let id;
  for (id = si.length - 1; id > 0; id--) {
    if (stringNumber >= si[id].v) break;
  }
  return (
    (stringNumber / si[id].v)
      .toFixed(1)
      .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[id].s
  );
};

export const colorRelatedToBackground = hexcolor => {
  if (!hexcolor) return 'light';

  const hex = hexcolor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'dark' : 'light';
};

export const getEnvName = () => {
  const env = process.env.REACT_APP_ENV_NAME?.toLowerCase() || 'production';
  if (env === 'development') return 'dev';
  if (env === 'staging') return 'stg';
  if (env === 'production') return 'prod';
  return env;
};

export const extractSocialLinks = string => {
  if (!string) return false;
  const array = string.split(',');
  const socials = {
    facebook: array.find(link => link.includes('facebook')) || '',
    twitter: array.find(link => link.includes('twitter')) || '',
    github: array.find(link => link.includes('github')) || '',
    linkedin: array.find(link => link.includes('linkedin')) || '',
    youtube: array.find(link => link.includes('youtube')) || '',
  };

  return socials;
};

export const getNotIncludedCharacter = (string1, characters) => {
  if (!string1 || !characters) return false;
  let notMatched = '';

  console.log(string1.split(''));
  console.log(characters);
  string1.split('').forEach(char => {
    // console.log(char);
    if (!characters.includes(char)) notMatched += char;
  });

  // remove duplicates
  const preparedNotMatched = Array.from(new Set(notMatched.split(''))).join('');

  return preparedNotMatched;
};

export const range = (start, end) => {
  let length = end - start + 1;
  /*
    Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export const formatDateYYYYMMDD = (date = new Date()) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit', });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return [year, month, day].join('-');
}