import styles from './Categories.module.sass';
import cn from 'classnames';
import Category from './Category';

export default function Categories({ categories }) {
    return (
        <div className={cn(styles.categories)}>
            {categories?.map((item, index) => (
                <div key={index}>
                    <Category category={item} index={index}/>
                </div>
            ))}
        </div>
    );
}