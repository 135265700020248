import React, { useState, useEffect } from 'react';
import styles from './Users.module.sass';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Item from './Item';
import cn from 'classnames';
import Card from 'components/Card';
import Form from 'components/Form';
import devscntrAuth from 'api/Instance/devscntrAuth';
import axios from 'axios';
import Checkbox from 'components/Checkbox';
import { adminEndpoints } from 'api/endpoints';
import { adminPageSize } from 'data/dataArrays';
import SkeletonLoader from './Item/Loader';
import Filtering from './Filtering';
import Search from 'components/Search';
import useBreakpoints from 'hooks/useBreakpoints';

const DUMMY_DATA = [
  {
    id: 46,
    image: '/images/default-avatar.png',
    display_name: 'Test',
    follower_count: 21,
    user_follows_count: 14,
    created_at: '2022-08-01T08:10:40.475114Z',
  },
  {
    id: 45,
    image: null,
    display_name: 'Test',
    follower_count: 21,
    user_follows_count: 14,
    created_at: '2022-08-01T08:10:40.475114Z',
  },
];

const labels = [
  {
    name: 'Nazwa użytkownika',
    style: 'username',
  },
  {
    name: 'Obserwacje',
    style: 'followers',
  },
  {
    name: 'Wyświetlenia',
    style: 'views',
  },
  {
    name: 'Ilość publikacji',
    style: 'activity_numbers',
  },
  {
    name: 'Status',
    style: 'status',
  },
];

const pageSize = adminPageSize;

const Users = () => {
  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['r-max', Infinity],
    ['r-lg', 1140],
    ['r-md', 768],
    ['r-sm', 568],
    ['r-sm-s', 450],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const authCtx = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [search, setSearch] = useState('');

  const urlSearch = useLocation().search;
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(
    new URLSearchParams(urlSearch).get('page') || 1
  );
  const [lastPage, setLastPage] = useState(true);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getUsers(cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, [search, urlSearch]);

  // get users from API
  const getUsers = async cancelToken => {
    setIsLoading(true);
    const params = `${urlSearch}&page_size=${pageSize}${
      search.length > 0 ? `&query=${search.toLowerCase()}` : ''
    }`;
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${adminEndpoints.users.show}${params}&is_rss=False`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      if (response.data.data.length < 1) {
        setCurrentPage(1);
      }

      const data = response.data.data;
      setUsers(data.filter(item => item.is_rss !== true));
      setLastPage(response.data.next === null);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('admin users canceled');
      } else {
        if (error?.response?.status === 404) {
          setCurrentPage(1);
        } else {
          console.log(error);
        }
      }
    }

  };

  const handleSelectAll = () => {
    if (selectedUsers?.length === 0) {
      setSelectedUsers(users?.map(item => item.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const sortUsers = users => {
    return users.length > 0 && users?.sort((a, b) => a.id - b.id);
  };

  useEffect(() => {
    if (isMounted) {
      setDisplayedUsers(users);
      setIsLoading(false);
    } else {
      setIsMounted(true);
    }
  }, [users]);

  // akcje dla zaznaczonych userow
  return (
    <div ref={x => setStylesRef(x)}>
      {authCtx.isAdmin ? (
        <Card
          className={styles.card}
          classCardHead={styles.head}
          title='Zarządzaj użytkownikami'
          classTitle={cn('title-yellow', styles.title)}
          head={
            <>
              <Search
                className={styles.form}
                value={search}
                setValue={setSearch}
                placeholder='Szukaj...'
                type='text'
                name='search'
                icon='search'
              />
            </>
          }
        >
          <Search
            className={styles.form_mobile}
            value={search}
            setValue={setSearch}
            placeholder='Szukaj...'
            type='text'
            name='search'
            icon='search'
          />
          <div className={styles.container}>
            <div className={styles.header}>
              <div className={styles.labels}>
                <div className={cn(styles.checkbox)}>
                  <Checkbox
                    className={styles.checkbox}
                    onChange={handleSelectAll}
                    value={selectedUsers?.length > 0}
                  />
                </div>
                {labels.map((label, index) => (
                  <div
                    key={`label_${index}`}
                    className={cn(styles.label, styles[label.style])}
                  >
                    {label.name}
                  </div>
                ))}
              </div>
              <Filtering currentPage={currentPage} />
            </div>
            {isLoading ? (
              <SkeletonLoader cards={pageSize} />
            ) : displayedUsers?.length > 0 ? (
              <div className={styles.users_list}>
                {displayedUsers.map(item => (
                  <Item
                    selected={selectedUsers.includes(item.id)}
                    setSelected={setSelectedUsers}
                    user={item}
                    key={`user_${item.id}`}
                  />
                ))}
              </div>
            ) : (
              <p className={styles.info}>Brak danych</p>
            )}

            <div className={styles.pagination}>
              {currentPage > 1 && (
                <button
                  className={cn(
                    'button-stroke button-small',
                    isLoading && 'disabled',
                    styles.button
                  )}
                  style={{ marginLeft: '12px' }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPage(prevState => --prevState);
                  }}
                  disabled={isLoading}
                >
                  Poprzednia
                </button>
              )}
              {!lastPage && (
                <button
                  className={cn(
                    'button-stroke button-small',
                    isLoading && 'disabled',
                    styles.button
                  )}
                  style={{ marginLeft: '12px' }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPage(prevState => ++prevState);
                  }}
                  disabled={isLoading}
                >
                  Następna
                </button>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Redirect to='/' />
      )}
    </div>
  );
};
export default Users;
