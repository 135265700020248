import React from 'react';
import styles from './AboutMe.module.sass';
import Card from 'components/Card';

import AboutMeDescription from './AboutMeDescription';
import AboutMeEmployments from './AboutMeEmployments';
import AboutMeEducation from './AboutMeEducation';
import AboutMeCertificates from './AboutMeCertificates';
import AboutMeSkills from './AboutMeSkills';
import AboutMeLanguages from './AboutMeLanguages';
import AboutMeInterests from './AboutMeInterests';
import AboutMeContact from './AboutMeContact';

const AboutMe = ({ data }) => {
  return (
    <Card className={styles.card}>
      {data?.description && (
        <AboutMeDescription
          description={data?.description}
          phone={data?.phone}
          email={data?.email}
        />
      )}
      {data?.employments?.length > 0 && <AboutMeEmployments employments={data?.employments} />}
      {data?.education?.length > 0 && <AboutMeEducation education={data?.education} />}
      {data?.skills?.length > 0 && <AboutMeSkills skills={data?.skills} />}
      {data?.certificates?.length > 0 && <AboutMeCertificates certificates={data?.certificates} />}
      {data?.interests?.length > 0 && <AboutMeInterests interests={data?.interests} />}
      {data.foreign_languages?.length > 0 && <AboutMeLanguages languages={data?.foreign_languages} />}
      {(data?.cv_phone || data?.cv_email) && <AboutMeContact phone={data?.cv_phone} email={data?.cv_email} />}
    </Card>
  );
};

export default AboutMe;
