import React, { useState } from 'react';
import Drafts from './components/Drafts';
import Card from 'components/Card';
import styles from './Publications.module.sass';
import classNames from 'classnames';
import PromoCodesSection from './components/PromoCodesSection';

export default function Publications() {
  const [page, setPage] = useState('drafts');
  const [active, setActive] = useState('drafts');
  const [codesActive, isCodesActive] = useState(false);
  const [publicationsTitle, setPublicationsTitle] =
    useState('Twoje publikacje');
  const [titleColor, setTitleColor] = useState('title-purple');
  const toggleActive = x => {
    setActive(x);
  };

  function pageDraft() {
    setPage('drafts');
    toggleActive('drafts');
    setPublicationsTitle('Twoje publikacje');
    setTitleColor('title-purple');
    isCodesActive(false);
  }
  function pageCourses() {
    setPage('courses');
    toggleActive('courses');
    setPublicationsTitle('Twoje kursy');
    setTitleColor('title-red');
    isCodesActive(false);
  }
  function pagePosts() {
    setPage('posts');
    toggleActive('posts');
    setPublicationsTitle('Twoje wpisy');
    setTitleColor('title-blue');
    isCodesActive(false);
  }
  function pageJobs() {
    setPage('jobs');
    toggleActive('jobs');
    setPublicationsTitle('Twoje oferty ');
    setTitleColor('title-green');
    isCodesActive(false);
  }

  function pageCodes() {
    setPage('codes');
    toggleActive('codes');
    setPublicationsTitle('Twoje kody rabatowe');
    setTitleColor('title-blue');
    isCodesActive(true);
  }

  return (
    <div>
      <Card className={styles.container}>
        <div className={styles.nav}>
          <button
            className={
              active === 'drafts'
                ? classNames(styles.button, styles.active)
                : styles.button
            }
            onClick={pageDraft}
          >
            Wszystko
          </button>
          <button
            className={
              active === 'courses'
                ? classNames(styles.button, styles.active)
                : styles.button
            }
            onClick={pageCourses}
          >
            Kursy
          </button>
          <button
            className={
              active === 'posts'
                ? classNames(styles.button, styles.active)
                : styles.button
            }
            onClick={pagePosts}
          >
            Wpisy
          </button>
          <button
            className={
              active === 'jobs'
                ? classNames(styles.button, styles.active)
                : styles.button
            }
            onClick={pageJobs}
          >
            Oferty 
          </button>
          <button
            className={
              active === 'codes'
                ? classNames(styles.button, styles.active)
                : styles.button
            }
            onClick={pageCodes}
          >
            Kody rabatowe
          </button>
        </div>

        {!codesActive ? (
          <Drafts
            selectedType={page}
            title={publicationsTitle}
            titleColor={titleColor}
          />
        ) : (
          <PromoCodesSection
            title={publicationsTitle}
            titleColor={titleColor}
          />
        )}
      </Card>
    </div>
  );
}
