import React, { useEffect, useRef, useState } from 'react';
import styles from './Course.module.sass';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Card from 'components/Card';
import cn from 'classnames';
import devscntrAuth from 'api/Instance/devscntrAuth';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import useAuth from 'hooks/useAuth';
import SkeletonLoader from 'screens/DetailsLayout/Loader';
import {
  accountsEndpoints,
  coursesEndpoints,
  levelsEndpoints,
} from 'api/endpoints';
import Panel from 'screens/DetailsLayout/Panel';
import AuthorHeader from 'screens/DetailsLayout/AuthorHeader';
import Content from 'screens/DetailsLayout/Content';
import Title from 'screens/DetailsLayout/Title';
import TagsList from 'screens/DetailsLayout/TagsList';
import CategoriesList from 'screens/DetailsLayout/CategoriesList';
import Comments from 'screens/DetailsLayout/Comments';
import useBreakpoints from 'hooks/useBreakpoints';
import AboutUser from 'components/AboutUser';
import Details from './Details';
import RelatedJobs from 'screens/DetailsLayout/RelatedJobs';
import LinksList from 'screens/DetailsLayout/LinksList';
import AppHelmet from 'components/AppHelmet';
import RelatedCourses from 'screens/DetailsLayout/RelatedCourses';
import RelatedPosts from 'screens/DetailsLayout/RelatedPosts';

const Course = () => {
  // UTILS----------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const isLoggedIn = authCtx.isLoggedIn;

  const history = useHistory();
  const location = useLocation();

  const [article, setArticle] = useState(null);
  const [jobsList, setJobsList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [postsList, setPostsList] = useState([]);
  const [doesCourseExist, setDoesCourseExist] = useState(true);

  const [author, setAuthor] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [authorLevel, setAuthorLevel] = useState('Level 0');
  const { slug } = useParams();

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 767],
  ];
  useBreakpoints(stylesRef, breakpoints);

  // UTILS----------------------------------------------------------------------UTILS
  // SCROLL TO SECTION----------------------------------------------SCROLL TO SECTION

  const section = useLocation().hash || null;
  const ratingRef = useRef(null);
  const commentsRef = useRef(null);

  useEffect(() => {
    if (ratingRef.current && section === '#rating') {
      const top = ratingRef.current.offsetTop;
      window.scrollTo({ top: top - 100, behavior: 'smooth' });
    }
    if (commentsRef.current && section === '#comments') {
      const top = commentsRef.current.offsetTop;
      window.scrollTo({
        top: top - 100,
        behavior: 'smooth',
      });
    }
  }, [article, ratingRef?.current, commentsRef?.current, section]);

  // SCROLL TO SECTION----------------------------------------------SCROLL TO SECTION
  // GET COURSE------------------------------------------------------------GET COURSE

  const refresher = () => {
    setRefresh(prev => !prev);
  };

  const getCourseId = async cancelToken => {
    try {
      const { data } = await axiosInstance.request({
        method: 'get',
        url: `${coursesEndpoints.idAndSlug}?slug=${slug}`,
        cancelToken: cancelToken.token,
      });
      if (!data?.id) history.goBack();
      getCourse(data.id, cancelToken);
    } catch (error) {
      console.log(error);
      setDoesCourseExist(false);
    }
  };

  const getCourse = async (id, cancelToken) => {
    try {
      const response = await axiosInstance.request({
        method: 'get',
        url: `${coursesEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
        headers: {
          view:
            sessionStorage.getItem(`course${id}Seen`) == null ? true : false,
        },
      });
      const data = response.data[0];
      if (!data.id) history.goBack();
      setJobsList(data?.related_jobs || []);
      setCoursesList(data?.related_courses || []);
      setPostsList(data?.related_posts || []);
      setArticle(data);
      fetchLevel(data.author);
      !article && authCtx.getLevel();
      sessionStorage.setItem(`course${data.id}Seen`, 'true');
    } catch (error) {
      console.log(error);
      setDoesCourseExist(false);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCourseId(cancelToken);

    if (!section) window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [slug, refresh]);

  // GET COURSE------------------------------------------------------------GET COURSE
  // GET ACCOUNT----------------------------------------------------------GET ACCOUNT

  const getAccountInfo = async cancelToken => {
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: 'get',
        url: accountsEndpoints.userData.userInfo,
        cancelToken: cancelToken.token,
        headers: {
          'USER-ID': article.display_name,
        },
      });
      setAuthor(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('account info canceled');
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (article) getAccountInfo(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [article]);

  // GET ACCOUNT----------------------------------------------------------GET ACCOUNT

  const fetchLevel = id => {
    devscntrAuth
      .get(levelsEndpoints.userLevel, { headers: { 'USER-ID': id } })
      .then(response => {
        setAuthorLevel(response.data.current_level);
      });
  };

  // Increasing External Link clicks count ----------------------------------------/

  const onExternalLinkClicked = () => {
    if (!sessionStorage.getItem(`externalLinkCourse${article.id}Seen`)) {
      axiosInstance
        .get(coursesEndpoints.externalLinkCount + `${article.id}/`)
        .then(response => {
          sessionStorage.setItem(`externalLinkCourse${article.id}Seen`, 'true');
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  // Increasing External Link clicks count ----------------------------------------/
  return (
    <>
      {doesCourseExist ? (
        article && author ? (
          <>
            <AppHelmet
              title={article?.meta_title}
              description={article?.meta_description}
              image={article?.image}
              type='article'
              url={`https://itcntr.com${location.pathname}`}
            >
              <meta
                property='og:article:published_time'
                content={article?.created_at}
              />
              <meta
                property='og:article:author'
                content={article?.display_name || 'itCNTR'}
              />
              <meta property='og:article:section' content='Kurs' />
              <meta
                property='og:article:tag'
                content={article?.tags[0]?.name}
              />
            </AppHelmet>
            <section ref={x => setStylesRef(x)}>
              <div className={styles.container} ref={ratingRef}>
                <div className={styles.content}>
                  <AuthorHeader
                    article={article}
                    type='course'
                    refresh={refresher}
                    rating={article?.rating_avg || 0}
                    userRating={article?.user_rating || 0}
                    isRss={article?.is_rss || false}
                  />
                  <Title
                    title={article?.title}
                    image={article?.image}
                    url={article?.reflink}
                    urlClicksCount={article.external_views}
                    urlClicked={onExternalLinkClicked}
                    buttonText='Przejdź do kursu'
                    date={article?.created_at?.slice(0, 10) || false}
                    locations={article?.localization || []}
                  />
                  <Details article={article} />
                  <Content
                    description={article?.content}
                    version={article?.editor_version}
                  />
                  <LinksList links={article.additional_url} />
                  <AboutUser user={author?.user} />
                  {article?.categories?.length > 0 && (
                    <CategoriesList
                      categories={article?.categories}
                      baseLink='/'
                    />
                  )}
                  {article?.tags?.length > 0 && (
                    <TagsList tags={article?.tags} baseLink='/' />
                  )}
                  <RelatedJobs items={jobsList} tags={article?.tags || []} />
                  <RelatedCourses
                    items={coursesList}
                    tags={article?.tags || []}
                  />
                  <RelatedPosts items={postsList} tags={article?.tags || []} />
                  <Comments id={article?.id} type='course' ref={commentsRef} />
                </div>
                <Panel
                  className={styles.panel}
                  type='course'
                  id={article?.id}
                  currentReaction={
                    article?.emotion_of_user?.length > 0
                      ? article?.emotion_of_user[0]?.emotion
                      : 0
                  }
                  likes={article?.emotions_likes_count}
                  dislikes={article?.emotions_dislikes_count}
                  author={article?.display_name}
                  author_image={article?.author_image}
                  refresh={refresher}
                  level={article?.xp_pkg}
                  isRss={article?.is_rss || false}
                  isLoggedIn={isLoggedIn}
                />
              </div>
            </section>
          </>
        ) : (
          <SkeletonLoader />
        )
      ) : (
        <Card className={styles.course_does_not_exist}>
          <p>Taki Kurs nie istnieje!</p>
          <button
            className={cn('button', styles.homebtn)}
            onClick={() => history.push('/')}
          >
            Powrót do listy kursów
          </button>
        </Card>
      )}
    </>
  );
};

export default Course;
