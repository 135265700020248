import style from './SkeletonLoaderPostDetail.module.sass';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function SkeletonLoaderPostDetail({ cards }) {

  const list = Array(cards).fill(0)

  return (
    <div className={style.container}>
      {list.map((_, index) => (
        <SkeletonTheme key={index}>
          <div className={style.skeleton} key={index}>
            <div className={style.item} >
              <Skeleton height={24} />
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </div>
  )
}
