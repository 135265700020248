import React from 'react';
import styles from './Category.module.sass';
import cn from 'classnames';
import Placement from '../../../../../components/Placement';

export default function Category({ category, index }) {

    return (
        <div className={cn(styles.container)} style={{backgroundColor: category?.color}}>
            <div className={styles.placement}>
                <Placement placement={index} isCategory/>
                <span>{category?.category || 'Brak nazwy'}</span>
            </div>
            <div className={styles.activity}>
                <div className={styles.activity_text}>
                    Popularność:
                </div>
                <span>{category?.number || 'Brak wyniku'}</span>
            </div>
        </div>
    );
}