import React, { useState, useEffect } from 'react';
import styles from './PostDetail.module.sass';
import { useParams } from 'react-router';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { adminEndpoints } from 'api/endpoints';
import Card from 'components/Card';
import PostDataDetail from './PostDataDetail';
import cn from 'classnames';
import PostDetailViews from './PostDetailViews';
import PostDetailComments from './PostDetailComments';
import PostDetailReactions from './PostDetailReactions';
import SkeletonLoaderPostDetail from './SkeletonLoaderPostDetail';

const postsEndpoint = adminEndpoints.dashboard.posts_stats

export default function PostDetail() {

    const authCtx = useAuth();
    const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

    const { id } = useParams();
    const [postData, setPostData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getPostData = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.request({
                method: 'get',
                url: postsEndpoint + `${id}/`,
            });
            setPostData(response?.data)
        } catch (error) {
            console.log(error);

        }
        setIsLoading(false)
    };

    useEffect(() => {
        getPostData();
    }, []);

    return (
        <div className={styles.container}>
            <Card
                className={styles.card}
                title={'Szczegółowe informacje o wpisie - ' + postData?.post_data?.title}
                classTitle={cn('title-blue', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderPostDetail cards={9} />
                ) : (
                    <PostDataDetail data={postData?.post_data} />
                )}
            </Card>
            <Card
                className={styles.card}
                title={'Unikatowe wyświetlenia'}
                classTitle={cn('title-green', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderPostDetail cards={10} />
                ) : (
                    <PostDetailViews data={postData?.unique_views} />
                )}
            </Card>
            <Card
                className={styles.card}
                title={'Komentarze'}
                classTitle={cn('title-yellow', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderPostDetail cards={10} />
                ) : (
                    <PostDetailComments data={postData?.comments} />
                )}
            </Card>
            <Card
                className={styles.card}
                title={'Reakcje'}
                classTitle={cn('title-purple', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderPostDetail cards={10} />
                ) : (
                    <PostDetailReactions data={postData?.reactions} />
                )}
            </Card>
        </div>
    );
}