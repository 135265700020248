import React, { useState, useEffect, useMemo } from 'react';
import styles from './CategoryTrends.module.sass';
import Card from 'components/Card';
import cn from 'classnames';
import Chart from "react-apexcharts";
import useDarkMode from 'use-dark-mode';

const CategoryTrends = ({ data }) => {

    const seniority = ['junior', 'mid', 'senior']
    const isDarkMode = useDarkMode()

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    const chartSeries = useMemo(() =>
    (data.map((item) =>
    ({
        name: item?.name,
        data: seniority.map((element, i) => (
            {
                valueInvented: item?.median?.series[i + 1]?.data[item?.median?.series[i + 1]?.data?.length - 1],
                x: element,
                y: item?.range[element][item?.range[element].length - 1].y
            })),
        color: item.color,
    }))), [data])

    const options = {
        theme:
        {
            mode: isDarkMode.value ? 'dark' : 'light',
        },
        chart: {
            type: 'rangeBar',
            background: 'none',
            height: 350,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false
            }
        },
        dataLabels: {
            enabled: true,
            style:
            {
                colors: ['#1A1D1F']
            },
            textAnchor: 'middle',
            formatter: function (val, opt) {
                return numberWithSpaces(opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].valueInvented)
            },
        },
        yaxis: {
            labels: {
                offsetX: -15,
                style:
                {
                    colors: [!isDarkMode ? 'black' : 'white']
                },
                formatter: function (value) {
                    return numberWithSpaces(value);
                }
            }
        },
    };

    const chart = (
        <Chart
            options={options}
            series={chartSeries}
            type="rangeBar"
            width="100%"
            height="400px"
        />
    )

    return (
        <>
            <Card
                className={cn(styles.card)}
                title='Trend'
                classTitle='title-blue'
            >
                {chart}
            </Card>
        </>
    );
};
export default CategoryTrends