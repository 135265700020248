import React, { useEffect, useState } from 'react';
import styles from './NewJob.module.sass';
import TooltipGlodal from 'components/TooltipGlodal';
import Modal from 'components/Modal';
import Schedule from 'components/Schedule';
import Panel from 'screens/NewLayouts/Panel';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import axios from 'axios';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Details from './Details';
import Salary from './Salary';
import useAuth from 'hooks/useAuth';
import Meta from 'components/Meta';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showError } from 'utils/showError';
import { jobFormat, jobRecruitment, jobSeniority } from 'data/dataArrays';
import { validateInput } from 'utils/validation';
import Content from 'screens/NewLayouts/Content';
import LocationsInput from 'components/InputBlocks/LocationsInput';
import { adminEndpoints, jobsEndpoints } from 'api/endpoints';
import Loader from 'screens/NewLayouts/Loader';
import TechnologiesInput from './TechnologiesInput';
import LinksInput from 'screens/NewLayouts/LinksInput';
import isJson from 'utils/isJson';
import ChooseCategoryBreadcrumbs from 'components/ChooseCategoryBreadcrumbs';

const EditJob = () => {
  // UTILS-------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [errorMsg, setErrorMsg] = useState('');
  const { id } = useParams();
  const history = useHistory();

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [article, setArticle] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState(false);

  // UTILS-------------------------------------------------------------------------UTILS
  // FIELDS-----------------------------------------------------------------------FIELDS

  const [title, setTitle] = useState('');
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const [detailsData, setDetailsData] = useState({
    format: jobFormat[0],
    seniority: jobSeniority[0],
    application_link: '',
    recruitment_format: jobRecruitment[0],
  });

  const [salary, setSalary] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companyLocations, setCompanyLocations] = useState([]);
  const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [categories, setCategories] = useState([]);

  // FIELDS-----------------------------------------------------------------------FIELDS
  // GET DATA-------------------------------------------------------------------GET DATA

  const getCompany = async cancelToken => {
    try {
      const response = await devscntrAuth.request({
        method: 'get',
        url: '/accounts/company/show/',
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      const locations = response.data.locations;
      setCompanyLocations(
        locations?.map(item => ({
          city: item.city,
          code: item.postcode,
          street: item.street,
          number: item.house_number,
        }))
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('cancel get company');
      } else {
        console.log(error);
      }
    }
  };

  const getJob = async cancelToken => {
    setFetching(true);
    try {
      // Request user data
      const response = await devscntrAuth.request({
        method: 'get',
        url: `${jobsEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
      });
      setArticle(response?.data[0] || {});
      setFetching(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('job detail canceled');
      } else {
        console.log(error);
      }
    }
  };

  const handleMetaTitleChange = event => {
    const val = event.target.value;
    setMetaTitle(val);
  };

  const handleMetaDescriptionChange = event => {
    const val = event.target.value;
    setMetaDescription(val);
  };

  //CATEGORY FUNCTIONS
  const checkIfArrayContains = (arrayToCheck, value) => {
    return arrayToCheck?.some(item => value.name === item.name);
  };

  const categoryFunction = item => {
    if (!checkIfArrayContains(categories, item)) {
      setCategories(categories => [...categories, item]);
    }
    if (checkIfArrayContains(categories, item)) {
      setCategories(current =>
        current.filter(object => object.name !== item.name)
      );
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getJob(cancelToken);
    getCompany(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id]);

  // INSERT DATA TO FIELDS
  useEffect(() => {
    if (article) {
      setTitle(article?.title || '');
      setMetaTitle(article?.meta_title || '');
      setMetaDescription(article?.meta_description || '');

      // DESCRIPTION
      if (article?.content) {
        if (article?.editor_version === 1 && isJson(article.content)) {
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(article.content))
            ) || EditorState.createEmpty()
          );
        }
        if (article?.editor_version === 2) {
          setEditorState(article.content);
        }
      }

      setDetailsData({
        format: jobFormat[parseInt(article?.format) - 1 || 0],
        seniority: jobSeniority[parseInt(article?.seniority) - 1 || 0],
        application_link: article?.application_link || '',
        recruitment_format:
          jobRecruitment[parseInt(article?.recruitment_format) - 1 || 0],
      });
      setSalary(article?.salary || []);
      if (article?.localization?.length > 0) {
        const newLocations = article?.localization?.map(item => ({
          city: item.city || '',
          code: item.code || '',
          street: item.street || '',
          number: item.number || '',
          location_lat: item.location_lat,
          location_lon: item.location_lon,
        }));
        setLocations(newLocations || []);
      }

      // TAGS
      let tagList = [];
      if (article?.tags?.length > 0) {
        tagList = article?.tags.map(item => ({
          name: item.name,
          colour: item.colour,
          level: item?.level || 0,
        }));
      }
      setTags(tagList);

      //SETTING CATEGORIES
      let categoriesList = [];
      if (article?.categories?.length > 0) {
        categoriesList = article?.categories.map(item => ({
          name: item.name,
          id: item.id,
          color: item.color,
        }));
      }

      setCategories(categoriesList);

      // LINKS
      let linksList = [];
      if (article?.additional_url?.length > 0) {
        linksList = article?.additional_url.map(item => ({
          title: item.description,
          url: item.url,
        }));
      }
      setLinks(linksList);

      if (authCtx.userID === article.author_id) setPermission(true);
      else if (authCtx.isAdmin) setPermission(true);
    }
  }, [article]);

  // GET DATA-------------------------------------------------------------------GET DATA
  // VALIDATION---------------------------------------------------------------VALIDATION

  const isValidUrl = url => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  const validateFields = data => {
    // TITLE
    if (!validateInput(data.title, 100, 3, 'Tytuł')) return;

    // DESCRIPTION
    if (article.editor_version === 1) {
      if (
        JSON.stringify(convertToRaw(editorState.getCurrentContent())).length <
        133
      ) {
        showError('Opis nie może być pusty');
        return false;
      }
    }
    if (article.editor_version === 2) {
      if (editorState?.trim(' ')?.length < 1) {
        showError('Opis nie może być pusty');
        return false;
      }
      if (editorState?.trim(' ')?.length > 16000) {
        showError('Przekroczono limit znaków. Skróć opis.');
        return false;
      }
    }

    // SALARY
    if (data.salary.length < 1) {
      showError('Musisz dodać przynajmniej jedną opcję wynagrodzenia');
      return false;
    }

    // DROPDOWNS
    if (!jobFormat.includes(detailsData.format)) {
      showError('Wybierz formę pracy');
      return false;
    }
    if (!jobSeniority.includes(detailsData.seniority)) {
      showError('Wybierz doświadczenie');
      return false;
    }

    // LOCATIONS
    if (data.localization.length < 1) {
      showError('Musisz dodać przynajmniej jedną lokalizację firmy');
      return false;
    }

    // TECHNOLOGIES
    if (data.tags.length < 1) {
      showError('Musisz dodać przynajmniej jedną wymaganą technologię');
      return false;
    }

    // VALIDATE REFLINK
    if (
      !isValidUrl(data?.application_link) &&
      data?.application_link?.length > 0
    ) {
      showError('Podany link nie jest poprawny');
      return false;
    }

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // CREATE JOB REQUEST-----------------------------------------------CREATE JOB REQUEST

  const editJobApi = async data => {
    setIsLoading(true);
    try {
      if (article.author_id === authCtx.userID) {
        const newData = { ...data, id: id };
        const response = await devscntrAuth.post(
          `${jobsEndpoints.update}`,
          newData,
          {
            headers: {
              method: 'update',
            },
          }
        );
      } else if (authCtx.isAdmin) {
        const response = await devscntrAuth.put(
          `${adminEndpoints.jobs.update}${id}/`,
          data
        );
      } else {
        return;
      }
      history.push(`/jobs/${id}`);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  // CREATE JOB REQUEST-----------------------------------------------CREATE JOB REQUEST
  // PREPARE DATA TO SEND-------------------------------------------PREPARE DATA TO SEND

  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const handleTitleChange = event => {
    const val = event.target.value;
    setTitle(val);
  };

  const handleSubmit = () => {
    console.log(detailsData);
    const newLocations = locations.map(item => ({
      city: item.city,
      code: item.code,
      street: item.street,
      adres: item.number,
    }));

    const preparedLinks = links.map(link => ({
      description: link.title,
      url: link.url,
    }));

    let preparedContent = null;
    if (article.editor_version === 1) {
      preparedContent = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
    }
    if (article.editor_version === 2) {
      preparedContent = editorState;
    }

    const preparedCategories = categories?.map((item,index)=>({
      name: item?.name,
      id: item?.id,
    }))

    let preparedData = {
      title: title,
      content: preparedContent,
      format: jobFormat.indexOf(detailsData.format) + 1,
      salary: salary,
      seniority: jobSeniority.indexOf(detailsData.seniority) + 1,
      application_link: detailsData.application_link,
      recruitment_format:
        jobRecruitment.indexOf(detailsData.recruitment_format) + 1,
      tags: tags,
      additional_url: preparedLinks,
      localization: newLocations,
      editor_version: article.editor_version,
      meta_title: metaTitle,
      meta_description: metaDescription,
      categories: preparedCategories,
    };
    console.log(preparedData);
    if (validateFields(preparedData)) {
      editJobApi(preparedData);
    }
  };

  // PREPARE DATA TO SEND-------------------------------------------PREPARE DATA TO SEND

  return !fetching ? (
    permission ? (
      <>
        <div className={styles.row}>
          <div className={styles.fields}>
            <Content
              className={styles.card}
              onTitleChange={handleTitleChange}
              onDescriptionChange={handleEditorStateChange}
              title={title}
              description={editorState}
              editorVersion={article?.editor_version}
            />
            <ChooseCategoryBreadcrumbs
              title='Kategorie'
              itemClicked={categoryFunction}
              loading={false}
              selectedCategories={categories}
            />
            <LinksInput
              links={links}
              setLinks={setLinks}
              className={styles.card}
            />
            <Salary data={salary} setData={setSalary} />
            <Details data={detailsData} setData={setDetailsData} internship={true}/>
            <LocationsInput
              locations={locations}
              setLocations={setLocations}
              savedLocations={companyLocations}
            />
            <Meta
              title={metaTitle}
              description={metaDescription}
              onTitleChange={handleMetaTitleChange}
              onDescriptionChange={handleMetaDescriptionChange}
            />
            <TechnologiesInput tags={tags} setTags={setTags} internship={true}/>
          </div>
        </div>
        <Panel
          setVisiblePreview={setVisiblePreview}
          setVisibleSchedule={setVisibleModal}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          buttonText='Zapisz zmiany'
        />
        <TooltipGlodal />
        <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
          <Schedule
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
          />
        </Modal>
      </>
    ) : (
      <Redirect to={`/jobs/${id}`} />
    )
  ) : (
    <Loader />
  );
};

export default EditJob;
