import React, { useState } from 'react';
import styles from './JZWIT.module.sass';
import cn from 'classnames';
import ItemJZWIT from './ItemJZWIT';
import Switch from 'components/Switch';

export default function JZWIT({ data, data_logged }) {

    const [currentData, setCurrentData] = useState(data)
    const [switchValue, setSwitchValue] = useState('not_logged')

    const switchChange = () => {
        if (switchValue === 'not_logged') {
            setSwitchValue('logged')
            setCurrentData(data_logged)
        }
        if (switchValue === 'logged') {
            setSwitchValue('not_logged')
            setCurrentData(data)
        }
    }

    return (
        <>
            <div className={styles.switch} style={switchValue === 'not_logged' ? { color: '#6F767E' } : {}}>
                <Switch onChange={switchChange} />
                Informacje tylko o zalogowanych użytkownikach
            </div>
            <div className={cn(styles.data)}>
                {currentData?.map((item, index) => (
                    <div key={index}>
                        <ItemJZWIT item={item} index={index} />
                    </div>
                ))}
            </div>
        </>
    );
}