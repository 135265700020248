import React, { useState } from 'react';
import styles from './Item.module.sass';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import {
  jobContractType,
  jobFormat,
  currencyList,
  jobSeniority,
} from 'data/dataArrays';
import useAuth from 'hooks/useAuth';
import ModalPin from 'components/ModalPin';
import { showLoginModal } from 'utils/showLoginModal';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import Tags from 'components/MiniItemsLayout/Tags';
import MoreOptions from 'components/MiniItemsLayout/MoreOptions';
import useBreakpoints from 'hooks/useBreakpoints';
import Locations from 'components/MiniItemsLayout/Locations';
import Categories from 'components/MiniItemsLayout/Categories';

const currencyCode = currencyList[0].code;

const Item = ({
  article,
  recommended = false,
  className,
  hideThumbnail,
  hideTags,
  onDelete,
  hideActions = false,
  jobs, 
  internships,
}) => {
  const authCtx = useAuth();

  const [stylesRef, setStylesRef] = useState(null);
  const breakpoints = [
    ['desktop', Infinity],
    ['mobile', 716],
    ['tiny', 300],
  ];
  useBreakpoints(stylesRef, breakpoints);

  let date;
  if (article?.created_at) {
    try {
      date = new Date(article?.created_at).toLocaleDateString();
    } catch (e) {
      date = '';
    }
  }

  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const [pinState, setPinState] = useState(article?.pinned);
  const [StarModalVisible, setStarModalVisible] = useState(false);
  const location = useLocation();

  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === '/pins') {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    // console.log('ARTICLE', article)

    setStarModalVisible(true);
  };
  // -----------------------------------------------------
  // -----------------------------------------------------

  return (
    article && (
      <article ref={x => setStylesRef(x)}>
        {/* <----- !!!! MODALPIN !!!! -----> */}

        {(StarModalVisible || redirectedUser || unpinPost) && (
          <ModalPin
            visible={StarModalVisible}
            onClose={() => setStarModalVisible(false)}
            article={article}
            setPinState={setPinState}
            redirectedUser={redirectedUser}
            setRedirectedUser={setRedirectedUser}
            unpinPost={unpinPost}
            setunpinPost={setunpinPost}
            pinUrl={'/jobs/user-job/'}
            idName={'job_id'}
          />
        )}
        <div
          className={cn(
            //!recommended ? styles.item : recommendedStyles.item,
            styles.item,
            className && className
          )}
        >
          {!hideThumbnail && (
            <Link to={internships ? `/internships/${article.id}` : `/jobs/${article.id}`} className={styles.image}>
              <Thumbnail
                className={cn(
                  article?.company_logo && styles.image_wrapper,
                  recommended && styles.fixed_image_wrapper,
                  article.is_rss && styles.no_padding
                )}
                image={article?.company_logo}
                imageFit={
                  !article?.company_logo && recommended ? 'cover' : 'contain'
                }
              />
            </Link>
          )}
          <div className={styles.details}>
            <section>
              <div className={styles.head}>
                <div
                  className={cn(styles.author, {
                    [styles.one_line]: recommended,
                  })}
                >
                  {article?.company_name || 'Brak nazwy firmy'}
                </div>

                {article?.categories?.length > 0 && (
                  <Categories
                    categories={article?.categories?.sort(
                      (a, b) => b.tags_count - a.tags_count
                    )}
                    baseLink={internships ? '/internships' : '/jobs'}
                  />
                )}

                {!recommended && !hideActions && (
                  <div className={styles.actions}>
                    <button
                      className={styles.pin_btn}
                      onClick={pinState ? unpinHandler : pinHandler}
                    >
                      <img
                        src={
                          pinState
                            ? '/images/Icons/heart/filled-color.svg'
                            : '/images/Icons/heart/stroke.svg'
                        }
                        alt='favourite'
                      />
                    </button>

                    <MoreOptions
                      item={article}
                      authorId={article?.author_id || null}
                      onDelete={onDelete}
                      type='job'
                    />
                  </div>
                )}
              </div>
              <div>
                <Link
                  to={internships ? `/internships/${article.id}` : `/jobs/${article.id}`}
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  <div className={styles.title}>
                    {article?.title || 'Brak tytułu'}
                  </div>
                </Link>
                {article?.seniority && (
                  <div className={styles.seniority}>
                    {jobSeniority[article?.seniority - 1]}
                  </div>
                )}
              </div>
            </section>

            <section>
              <div className={cn(styles.row, styles.col_reverse)}>
                <div className={styles.salary}>
                  {article?.salary?.length < 1 && <p>Brak podanej pensji</p>}
                  {article?.salary?.length > 0 && (
                    <>
                      <p>{jobContractType[article?.salary[0]?.type - 1]}: </p>
                      {article?.salary[0].type == '1'
                        ? `${parseFloat(
                            article?.salary[0]?.hour_wage
                          ).toLocaleString()} ${currencyCode}/h`
                        : `${parseFloat(
                            article?.salary[0]?.salary_from
                          ).toLocaleString()} ${currencyCode} - ${parseFloat(
                            article?.salary[0]?.salary_to
                          ).toLocaleString()} ${currencyCode}`}
                    </>
                  )}
                </div>
                {article?.format && (
                  <div className={styles.format}>
                    {jobFormat[article?.format - 1]}
                  </div>
                )}
              </div>
              {!recommended && (
                <div className={cn(styles.row, styles.foot, styles.mobile_row)}>
                  {article?.localization?.length > 0 ? (
                    <Locations
                      className={styles.locations}
                      locations={article?.localization || []}
                    />
                  ) : (
                    <p className={styles.localization_info}>Brak lokalizacji</p>
                  )}

                  <div className={styles.date}>{date}</div>
                </div>
              )}
            </section>
          </div>
        </div>
      </article>
    )
  );
};

export default Item;
