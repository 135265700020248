import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Panel.module.sass';
import Icon from 'components/Icon';
import ModalAuthorSettings from 'components/ModalAuthorSettings';
import { store } from 'store/rootReducer';
import { showShareModal } from 'store/shareModal/actions';
import useAuth from 'hooks/useAuth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { showLoginModal } from 'utils/showLoginModal';
import { Link } from 'react-router-dom';
import { emotionChangeDelay } from 'data/dataArrays';
import {
  coursesEndpoints,
  postsEndpoints,
  supportEndpoints,
} from 'api/endpoints';
import { showError } from 'utils/showError';
import ModalReport from 'components/ModalReport';

const shareUrls = {
  post: 'https://devscntr.com/post/',
  course: 'https://devscntr.com/course/',
  job: 'https://devscntr.com/jobs/',
};

// REACTION ENDPOINTS ASSIGNED TO TYPE
const reactionEndpoints = {
  post: postsEndpoints.reaction,
  course: coursesEndpoints.reaction,
};

const reportEndpoints = {
  post: supportEndpoints.reportContent.posts.article,
  course: supportEndpoints.reportContent.courses.article,
  job: supportEndpoints.reportContent.jobs.article,
};

const Panel = ({
  className,
  type,
  id,
  image,
  currentReaction,
  likes,
  dislikes,
  author_image,
  author,
  refresh,
  level,
  isRss,
  isLoggedIn,
}) => {
  // AUTH CONTEXT
  const authCtx = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [visibleAuthorModal, setVisibleAuthorModal] = useState(false);

  // REACTIONS-------------------------------------------------------------------------REACTIONS
  const reactionUrl = reactionEndpoints[type] ?? '';
  const [allowClick, setAllowClick] = useState(true);
  const [reaction, setReaction] = useState(currentReaction || 0);
  const [emotions, setEmotions] = useState({
    likes: likes || 0,
    dislikes: dislikes || 0,
  });
  let permission = false;
  if (authCtx.userName === author) permission = true;
  else if (authCtx.isAdmin) permission = true;
  const userLevel = level?.error ? level.current_level : null;
  // CHANGE REACTION
  const handleReaction = newReaction => {
    // CHECK IF USER IS LOGGED IN
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    // CHECK IF REACTION IS NOT THE SAME
    if (reaction == newReaction) return;

    // ANTI-SPAM
    if (!allowClick) return;
    setAllowClick(false);
    setTimeout(() => setAllowClick(true), emotionChangeDelay);

    // SET NEW REACTION, SET NUMBERS
    setReaction(newReaction);
    setEmotions({
      likes: newReaction === 1 ? likes + 1 : likes,
      dislikes: newReaction === 2 ? dislikes + 1 : dislikes,
    });
  };

  console.log(permission)
  console.log(isLoggedIn)

  // SEND TO API
  const sendReaction = async () => {
    // SET JSON ADAPTED TO TYPE
    let data = {};
    if (type === 'post') data = { post: id, emotion: reaction };
    if (type === 'course') data = { course_id: id, emotion: reaction };

    // SEND
    try {
      const response = await devscntrAuth.post(reactionUrl, data);
      authCtx.getLevel();
      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // CALL API ON REACTION CHANGE
  useEffect(() => {
    if (isMounted) {
      sendReaction();
    } else {
      setIsMounted(true);
    }
  }, [reaction]);

  //const shareUrl = shareUrls[type] ?? "";
  const handleShare = () => {
    store.dispatch(showShareModal(window.location.href, image));
  };

  // REPORT CONTENT
  const [reportVisible, setReportVisible] = useState(false);
  const reportUrl = reportEndpoints[type] ?? '';

  const handleReport = async (title, description) => {
    let data;
    switch (type) {
      case 'post':
        data = { post: id, title: title, description: description };
        break;
      case 'course':
        data = { course: id, title: title, description: description };
        break;
      case 'job':
        data = { job: id, title: title, description: description };
      default:
        break;
    }
    console.log(data);
    try {
      const response = await devscntrAuth.post(reportUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response?.data);
      console.log('reported');
    } catch (error) {
      console.log(error);
      showError('Zgłoszenie nie powiodło się. Spróbuj ponownie później');
    }
  };

  return (
    <>
      <ModalReport
        visible={reportVisible}
        onClose={() => setReportVisible(false)}
        onSubmit={handleReport}
      />
      <div className={cn(styles.panel, className)}>
        {!isRss && (
          <Link to={`/profile/${author}`} className={styles.avatar}>
            <img
              src={author_image || '/images/default-avatar.png'}
              alt='Avatar'
            />
            {userLevel && (
              <div className={styles.level}>{userLevel.substring(6)}</div>
            )}
          </Link>
        )}
        <button className={styles.btns} onClick={handleShare}>
          <Icon name='share' size='24' />
        </button>
        {type !== 'job' && (
          <>
            <button className={styles.btns} onClick={() => handleReaction(1)}>
              <Icon
                name={reaction == 1 ? 'thumbs-up-fill' : 'thumbs-up'}
                size='24'
                viewBox={'0 0 24 24'}
              />
            </button>
            <button className={styles.btns} onClick={() => handleReaction(2)}>
              <Icon
                name={reaction == 2 ? 'thumbs-down-fill' : 'thumbs-down'}
                size='24'
                viewBox={'0 0 24 24'}
              />
            </button>
          </>
        )}
        <button
          className={styles.btns}
          onClick={() => setReportVisible(true)}
          style={{ transform: 'rotate(180deg)' }}
        >
          <Icon name='info' size='24' />
        </button>
        {permission && isLoggedIn &&(
          <button
            className={styles.btns}
            onClick={() => setVisibleAuthorModal(true)}
          >
            <Icon name='setting' size='24' />
          </button>
        )}
      </div>
      {permission && isLoggedIn &&(
        <ModalAuthorSettings
          id={id}
          type={type}
          visible={visibleAuthorModal}
          setVisible={setVisibleAuthorModal}
          author={author}
        />
      )}
    </>
  );
};

export default Panel;
