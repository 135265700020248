import React, { useState, useEffect, useRef } from 'react';
import styles from './Dashboard.module.sass';
import Card from 'components/Card';
import cn from 'classnames';
import { adminEndpoints } from 'api/endpoints';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import Users from './Users';
import Categories from './Categories';
import SkeletonLoader from './SkeletonLoader';
import Earnings from './Earnings';
import JZWIT from './JZWIT';
import Courses from './Courses';
import Posts from './Posts';
import Stats from './Stats';
import SkeletonLoaderStats from './SkeletonLoaderStats';
import TimePeriod from './TimePeriod';
import { formatDateYYYYMMDD } from 'utils';

const dashboardEndpoint = adminEndpoints.dashboard.dashboard

const Dashboard = () => {

  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const [dashboardData, setDashboardData] = useState([])

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [startDateParam, setStartDateParam] = useState('');
  const [endDateParam, setEndDateParam] = useState('');

  const [isLoading, setIsLoading] = useState(true)

  const getDashboardData = async () => {
    try {
      setIsLoading(true)
      const response = await axiosInstance.request({
        method: 'get',
        url: (startDateParam || endDateParam) ? (dashboardEndpoint + '?' + startDateParam + '&' + endDateParam) : (dashboardEndpoint),
      });
      setDashboardData(response?.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    if (Object.prototype.toString.call(startDate) === "[object Date]") {
      setStartDateParam('from=' + formatDateYYYYMMDD(startDate))
    }
    else {
      setStartDateParam('')
    }
  }, [startDate]);

  useEffect(() => {
    if (Object.prototype.toString.call(endDate) === "[object Date]") {
      setEndDateParam('to=' + formatDateYYYYMMDD(endDate))
    } else {
      setEndDateParam('')
    }
  }, [endDate]);

  useEffect(() => {
    getDashboardData();
  }, [startDateParam, endDateParam]);

  return (
    <>
      <div
        className={styles.container}
      >
        <TimePeriod setStartDate={setStartDate} setEndDate={setEndDate} />
        <div className={styles.stats}>
          {isLoading ? (
            <SkeletonLoaderStats cards={4} title={'Statystyki ogólne'} />
          ) : (
            <Stats
              new_users={dashboardData.new_users}
              tests_taken={dashboardData.tests_taken}
              unique_users_logged_in={dashboardData.unique_users_logged_in}
              users_past_day={dashboardData.users_past_day}
            />
          )}
          {isLoading ? (
            <SkeletonLoaderStats cards={4} title={'Statystyki kursów'} />
          ) : (
            <Courses data={dashboardData.course} />
          )}
          {isLoading ? (
            <SkeletonLoaderStats cards={4} title={'Statystyki postów'} />
          ) : (
            <Posts data={dashboardData.post} />
          )}
        </div>

        <Card
          className={styles.card}
          classCardHead={styles.card_head}
          title='Najbardziej aktywni użytkownicy'
          classTitle={cn('title-blue', styles.title)}
        >
          {isLoading ? (
            <SkeletonLoader cards={5} />
          ) : (
            <div className={styles.section_text}>
              <div className={styles.activity_text}>
                *Aktywność jest sumą ilości postów, kursów, komentarzy, lików, dislików oraz ocen.
              </div>
              <Users users={dashboardData.most_active_users} />
            </div>
          )}
        </Card>

        <Card
          className={styles.card}
          classCardHead={styles.card_head}
          title='Najpopularniejsze kategorie'
          classTitle={cn('title-green', styles.title)}
        >

          {isLoading ? (
            <SkeletonLoader cards={5} />
          ) : (
            <div className={styles.section_text}>
              <div className={styles.activity_text}>
                *Popularność jest sumą ilości obiektów, do których kategoria jest przypisana.
              </div>
              <Categories categories={dashboardData.most_popular_categories} />
            </div>
          )}
        </Card>

        <Card
          className={styles.card}
          classCardHead={styles.card_head}
          title='Zarobki w IT - najpopularniejsze kategorie'
          classTitle={cn('title-red', styles.title)}
        >
          {isLoading ? (
            <SkeletonLoader cards={5} />
          ) : (
            <>
              <Earnings earnings={dashboardData.earnings_most_popular} />
            </>
          )}
        </Card>

        <Card
          className={styles.card}
          classCardHead={styles.card_head}
          title='Jak zacząć w IT - najczęściej odwiedzane'
          classTitle={cn('title-yellow', styles.title)}
        >
          {isLoading ? (
            <SkeletonLoader cards={5} />
          ) : (
            <>
              <JZWIT data={dashboardData.jzwit_most_popular} data_logged={dashboardData.jzwit_most_popular_logged_in} />
            </>
          )}
        </Card>
      </div>
    </>
  );
};
export default Dashboard;
