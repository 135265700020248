import React, { useState } from 'react';
import styles from './Profile.module.sass';
import cn from 'classnames';
import TextInput from 'components/TextInput';
import PhoneNumberInput from 'components/PhoneNumberInput';
import AvatarEditor from 'components/AvatarEditor';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { adminEndpoints } from 'api/endpoints';
import { validateInput } from 'utils/validation';
import { validationRules } from 'data/validationRules';
import Editor from 'components/Editor';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { showSuccess } from 'utils/showSuccess';
import BackgroundImageEditor from 'components/BackgroundImageEditor';

const Profile = ({ user, refresh }) => {
  // UTILS----------------------------------------------------------------------------UTILS

  const bgImgRef = {};
  const [userImage, setUserImage] = useState(user.image || null);
  const [userBgImage, setUserBgImage] = useState(user.background_image || null);

  // UTILS----------------------------------------------------------------------------UTILS
  // FIELDS--------------------------------------------------------------------------FIELDS

  const [displayName, setDisplayName] = useState(user?.display_name || '');
  const [firstname, setFirstname] = useState(user?.first_name || '');
  const [lastname, setLastname] = useState(user?.last_name || '');

  const [email, setEmail] = useState(user?.email || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [file, setFile] = useState(null);
  const [bgFile, setBgFile] = useState(null);

  const [editorState, setEditorState] = useState(
    user?.bio
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(user?.bio)))
      : EditorState.createEmpty()
  );
  const handleEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  // FIELDS--------------------------------------------------------------------------FIELDS
  // VALIDATION------------------------------------------------------------------VALIDATION

  const validation = () => {
    // DISPLAY NAME
    if (
      !validateInput(
        displayName.trim(),
        validationRules.displayName.maxChars,
        validationRules.displayName.minChars,
        validationRules.displayName.name,
        validationRules.displayName.security
      )
    ) {
      return false;
    }

    // FIRST NAME
    if (
      !validateInput(
        firstname.trim(),
        validationRules.firstname.maxChars,
        validationRules.firstname.minChars,
        validationRules.firstname.name,
        validationRules.firstname.security
      )
    ) {
      return false;
    }

    // LAST NAME
    if (
      !validateInput(
        lastname.trim(),
        validationRules.lastname.maxChars,
        validationRules.lastname.minChars,
        validationRules.lastname.name,
        validationRules.lastname.security
      )
    ) {
      return false;
    }

    // EMAIL
    if (
      !validateInput(
        email.trim(),
        validationRules.email.maxChars,
        validationRules.email.minChars,
        validationRules.email.name,
        validationRules.email.security
      )
    ) {
      return false;
    }

    return true;
  };

  // VALIDATION------------------------------------------------------------------VALIDATION
  // PREPARE DATA--------------------------------------------------------------PREPARE DATA

  const prepareData = () => {
    let data;
    data = {
      display_name: displayName.trim(),
      first_name: firstname.trim(),
      last_name: lastname.trim(),
      // bio: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      email: email.trim(),
      phone: phone.trim(),
    };
    if (file) data = { ...data, image: file };
    if (bgFile) data = { ...data, background_image: bgFile };

    return data;
  };

  // PREPARE DATA--------------------------------------------------------------PREPARE DATA
  // UPDATE API CALL--------------------------------------------------------UPDATE API CALL

  const updateProfile = async data => {
    try {
      const response = await devscntrAuth.put(
        `${adminEndpoints.users.update}${user.id}/`,
        data,
        {
          headers: {
            method: 'update',
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      showSuccess('Zmiany zapisane pomyślnie');
      refresh && refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if (validation()) {
      const preparedData = prepareData();
      updateProfile(preparedData);
    }
  };

  // UPDATE API CALL--------------------------------------------------------UPDATE API CALL

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={cn('title-blue', styles.section_header)}>Grafika</div>
        <div className={styles.images}>
          <AvatarEditor
            userId={user.id}
            image={userImage}
            setImage={setUserImage}
            setFile={setFile}
          />
          <BackgroundImageEditor
            className={styles.background_image}
            userId={user.id}
            image={userBgImage}
            setImage={setUserBgImage}
            setFile={setBgFile}
          />
        </div>
      </div>
      <div className={styles.section}>
        <div className={cn('title-blue', styles.section_header)}>
          Informacje o użytkowniku
        </div>
        <div className={styles.fields}>
          <TextInput
            className={styles.field}
            label='Nazwa użytkownika'
            name='displayName'
            type='text'
            tooltip='Wyświetlana nazwa użytkownika'
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
          />
          <TextInput
            className={styles.field}
            label='Email'
            name='email'
            type='text'
            value={email}
            onChange={e => setEmail(e.target.value)}
            readOnly
          />
          <TextInput
            className={styles.field}
            label='Imię'
            name='firstname'
            type='text'
            value={firstname}
            onChange={e => setFirstname(e.target.value)}
          />
          <TextInput
            className={styles.field}
            label='Nazwisko'
            name='lastname'
            type='text'
            value={lastname}
            onChange={e => setLastname(e.target.value)}
          />
          <PhoneNumberInput
            className={styles.field}
            label='Numer telefonu'
            name='phone'
            value={phone}
            onChange={setPhone}
            readOnly
          />
        </div>
        {/* <Editor
          editorState={editorState}
          onEditorChange={handleEditorStateChange}
          classEditor={styles.editor}
          label='O mnie'
          tooltip='Opis'
        /> */}
        <button className='button' onClick={handleSubmit}>
          Zapisz zmiany
        </button>
      </div>
    </div>
  );
};

export default Profile;
