import React, { useState, useEffect } from 'react';
import styles from './AboutUser.module.sass';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { convertFromRaw, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';
import EditorContentRender from 'components/Editor/EditorContentRender';

const AboutUser = ({ user }) => {
  const [description, setDescription] = useState(null);

  // useEffect(() => {
  //   if (user.bio) {
  //     let fixedDescription = EditorState.createWithContent(
  //       convertFromRaw(JSON.parse(user.bio))
  //     );
  //     setDescription(convertToHTML(fixedDescription.getCurrentContent()));
  //   }
  // }, [user]);

  return (
    user?.bio &&
    user?.bio?.length > 131 && (
      <div className={styles.container}>
        <div className={cn('title-blue', styles.subtitle)}>O autorze</div>
        <div className={styles.about}>
          <Link to={`/profile/${user.id}`}>
            <div className={styles.image}>
              <img src={user.image || '/images/default-avatar.png'} alt='' />
            </div>
          </Link>

          <div className={styles.info}>
            <Link to={`/profile/${user.id}`}>
              <div className={styles.name}>
                {user?.display_name || 'Brak nazwy'}
              </div>
            </Link>
            {/* {user?.bio && user?.bio?.length > 131 ? (
              <EditorContentRender className={styles.content} data={user.bio} />
            ) : (
              // <div
              //   className={styles.content}
              //   dangerouslySetInnerHTML={{
              //     __html: description,
              //   }}
              // ></div>
              <div className={styles.message}>
                Użytkownik nie dodał jeszcze swojego opisu
              </div>
            )} */}
          </div>
        </div>
      </div>
    )
  );
};

export default AboutUser;
