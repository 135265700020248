import React, { useState, useEffect } from 'react';
import styles from './PostDetailComments.module.sass';

export default function PostDetailComments({ data }) {

    return (
        <div className={styles.container}>
            {data?.map((item, index) => (
                <div className={styles.item} key={index}>
                    <div className={styles.text}>
                        <span>Użytkownik </span>{item?.author ? item?.author : 'Niezalogowany'}
                        <span> skomentował w dniu </span>{new Date(item?.created_at).toISOString().split('T')[0]}
                        <span> o godzinie </span>
                        {(new Date(item?.created_at).getHours()<10 ? '0':'')+new Date(item?.created_at).getHours()}
                        :
                        {(new Date(item?.created_at).getMinutes()<10 ? '0':'')+new Date(item?.created_at).getMinutes()}
                    </div>
                </div>
            ))}
        </div>
    );
}