import React, { useEffect, useState } from 'react';
import styles from './Settings.module.sass';
import cn from 'classnames';
import { Switch, Route, Redirect, useLocation } from 'react-router';
import useBreakpoints from 'hooks/useBreakpoints';

import Submenu from 'components/Submenu';

import devscntrAuth from 'api/Instance/devscntrAuth';
import { accountsEndpoints } from 'api/endpoints';

import Events from './Events';
import Company from './Company';
import Privacy from './Privacy';
import BlockedUsers from './BlockedUsers';
import Tags from './Tags';
import ProfileData from './ProfileData';
import { extractSocialLinks } from 'utils';
import useAuth from 'hooks/useAuth';
import Account from './Account';

const menu = [
  {
    title: 'Dane profilu',
    url: '/settings/profile-data',
  },
  {
    title: 'Konto',
    url: '/settings/account',
  },
  {
    title: 'Preferowane tagi',
    url: '/settings/my-tags',
  },
  {
    title: 'Zablokowane konta',
    url: '/settings/blocked-accounts',
  },
  {
    title: 'Moja firma',
    url: '/settings/my-company',
  },
  {
    title: 'Historia zdarzeń',
    url: '/settings/events-history',
  },
  {
    title: 'Prywatność',
    url: '/settings/privacy',
  },
];

// API URLs
const GET_ACCOUNT_INFO_URL = accountsEndpoints.userData.myAccount;
const UPDATE_PROFILE_URL = accountsEndpoints.userData.myAccount;
const UPDATE_SOCIALS = accountsEndpoints.userData.userInfo;
const CHANGE_EMAIL_REQUEST_URL = '/accounts/email/change-requests';
const UPDATE_EMAIL_REQUEST_URL = '/accounts/email/update-requests';
const UPDATE_PHONE_URL = '/accounts/phone/change-requests';
const UPDATE_PROFILE_IMG_URL = accountsEndpoints.userData.profileImage;
const UPDATE_BACKGROUND_IMG_URL = accountsEndpoints.userData.backgroundImage;
const UPDATE_PROFILE_BG_IMG_URL = '/accounts/me/background-image-upload';

const Settings = () => {
  // UTILS----------------------------------------------------------------------------UTILS
  const authCtx = useAuth();
  const [menuOptions, setMenuOptions] = useState(menu || []);
  const location = useLocation();

  const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ['desktop', Infinity],
    ['tablet', 1024],
    ['mobile', 768],
  ];
  useBreakpoints(stylesRef, breakpoints);

  const [refresh, setRefresh] = useState(false);
  const refreshData = () => setRefresh(prev => !prev);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // UTILS----------------------------------------------------------------------------UTILS
  // STATES--------------------------------------------------------------------------STATES

  const [data, setData] = useState(null);
  const [blockList, setBlockList] = useState([]);
  const [agreements, setAgreements] = useState({
    personalDetails: true,
    privacyPolitics: true,
  });

  // STATES--------------------------------------------------------------------------STATES
  // GET ACCOUNT----------------------------------------------------------------------GET ACCOUNT

  const getAccountInfo = async signal => {
    try {
      // Request user data
      const response = await devscntrAuth.request({
        method: 'GET',
        url: GET_ACCOUNT_INFO_URL,
        signal: signal,
      });
      const resData = response?.data;
      const socials = extractSocialLinks(
        response?.data?.data?.user?.social_links
      );
      // Set profile data to state
      setData(resData);

      // Set user's bio, if API return null, set empty string
      const description = response?.data?.bio || '';
      // Set bio Editor state if user's bio content is not empty
      // if (description !== '') {
      //   setEditorState(
      //     EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
      //   );
      // }
      console.log(response?.data);
    } catch (e) {
      if (!signal?.aborted) {
        console.error(e);
      }
    }
  };

  console.log(data)

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    getAccountInfo(signal);

    return () => {
      abortController.abort();
    };
  }, [refresh]);

  // GET ACCOUNT----------------------------------------------------------------------GET ACCOUNT
  // useEffect(() => {
  //   setBlockList(authCtx?.blockedUsers || []);
  // }, [authCtx?.blockedUsers]);

  return (
    <section ref={x => setStylesRef(x)}>
      <div className={styles.container}>
        <Submenu classNames={styles.nav} menu={menuOptions || menu} />
        <div className={styles.content}>
          <Switch>
            <Route exact path='/settings'>
              <Redirect to='/settings/profile-data' />
            </Route>

            <Route exact path='/settings/profile-data'>
              <ProfileData
                data={data}
                setData={setData}
                refreshData={refreshData}
              />
            </Route>

            <Route exact path='/settings/account'>
              <Account />
            </Route>

            <Route exact path='/settings/my-tags'>
              <Tags />
            </Route>

            <Route exact path='/settings/blocked-accounts'>
              {/* <BlockedUsers blockedIdList={blockList || []} /> */}
              <BlockedUsers />
            </Route>

            <Route exact path='/settings/my-company'>
              <Company data={data?.data?.user?.company} refresh={refreshData} />
            </Route>

            <Route exact path='/settings/events-history'>
              <Events />
            </Route>

            <Route exact path='/settings/privacy'>
              <Privacy agreements={agreements} />
            </Route>

            <Route path='/settings/*'>
              <Redirect to='/settings/profile-data' />
            </Route>
          </Switch>
        </div>
      </div>
    </section>
  );
};

export default Settings;
