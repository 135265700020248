import React, { useEffect, useState } from 'react';
import styles from './AuthorHeader.module.sass';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { showLoginModal } from 'utils/showLoginModal';
import useAuth from 'hooks/useAuth';
import ModalPin from 'components/ModalPin';
import Author from 'components/MiniItemsLayout/Author';
import RatingInput from 'screens/DetailsLayout/RatingInput';
import FollowUserButton from 'components/FollowUserButton';
import useBreakpoints from 'hooks/useBreakpoints';
import Views from '../Views';

const AuthorHeader = ({
  article,
  type,
  refresh,
  author,
  authorId,
  rating,
  userRating,
  isRss,
}) => {
  // UTILS-----------------------------------------------------------------UTILS

  const authCtx = useAuth();

  // const [stylesRef, setStylesRef] = useState(null); // element DOM dla resizeObserver-a
  // const breakpoints = [
  //   ["desktop", Infinity],
  //   ["mobile", 716],
  // ];
  // useBreakpoints(stylesRef, breakpoints);

  // UTILS-----------------------------------------------------------------UTILS
  // PINS-------------------------------------------------------------------PINS

  const [pinState, setPinState] = useState(article?.pinned || false);

  const [StarModalVisible, setStarModalVisible] = useState();
  const location = useLocation();
  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === '/pins') {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setStarModalVisible(true);
  };

  useEffect(() => {
    setPinState(article.pinned);
    if (pinState !== null) setPinState(article?.pinned);
  }, [article?.pinned]);

  const refreshFollowers = () =>
  {
    window.location.reload();
  }

  // PINS-------------------------------------------------------------------PINS

  return (
    // <section ref={(x) => setStylesRef(x)}>
    <section>
      {(StarModalVisible || redirectedUser || unpinPost) && (
        <ModalPin
          visible={StarModalVisible}
          onClose={() => setStarModalVisible(false)}
          article={article}
          setPinState={setPinState}
          redirectedUser={redirectedUser}
          setRedirectedUser={setRedirectedUser}
          unpinPost={unpinPost}
          setunpinPost={setunpinPost}
          pinUrl={'/posts/article/user-post/'}
          idName={'post_id'}
        />
      )}

      <div className={styles.container}>
        <div className={styles.col}>
          {type === 'job' && author ? (
            <Author
              className={styles.author}
              id={article?.author_id}
              username={author?.display_name}
              image={author?.image}
              level={author?.xp?.current_level}
              isRss={isRss}
            />
          ) : (
            <Author
              className={styles.author}
              id={article?.author}
              username={article?.display_name}
              image={article?.author_image}
              level={article?.xp_pkg}
              isRss={isRss}
            />
          )}
          {!isRss && (
            <FollowUserButton userId={article.author_id || article.author} refresh={refreshFollowers}/>
          )}

          <Views viewsCount={article.views} />
          {type !== 'job' && (
            <div className={styles.rating}>
              <RatingInput
                id={article.id}
                author={article.author}
                articleRating={rating || 0}
                userRating={userRating || 0}
                type={type}
                refresh={refresh}
              />
            </div>
          )}
        </div>
        <div className={styles.col}>
          <button
            className={cn(styles.pin_button)}
            onClick={pinState ? unpinHandler : pinHandler}
          >
            <img
              src={
                pinState
                  ? '/images/Icons/heart/filled-color.svg'
                  : '/images/Icons/heart/stroke.svg'
              }
              alt='favourite'
            />
          </button>
        </div>
      </div>
    </section>
  );
};
export default AuthorHeader;
