import React, { useState, useEffect } from 'react';
import styles from './CourseDetail.module.sass';
import { useParams } from 'react-router';
import devscntrNoauth from 'api/Instance/devscntrNoauth';
import devscntrAuth from 'api/Instance/devscntrAuth';
import useAuth from 'hooks/useAuth';
import { adminEndpoints } from 'api/endpoints';
import Card from 'components/Card';
import CourseDataDetail from './CourseDataDetail';
import cn from 'classnames';
import CourseDetailViews from './CourseDetailViews';
import CourseDetailComments from './CourseDetailComments';
import CourseDetailReactions from './CourseDetailReactions';
import SkeletonLoaderCourseDetail from './SkeletonLoaderCourseDetail';

const coursesEndpoint = adminEndpoints.dashboard.courses_stats

export default function CourseDetail() {

    const authCtx = useAuth();
    const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
    const [isLoading, setIsLoading] = useState(false)

    const { id } = useParams();
    const [courseData, setCourseData] = useState([])

    const getCourseData = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.request({
                method: 'get',
                url: coursesEndpoint + `${id}/`,
            });
            setCourseData(response?.data)
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        getCourseData();
    }, []);

    return (
        <div className={styles.container}>
            <Card
                className={styles.card}
                title={'Szczegółowe informacje o kursie - ' + courseData?.course_data?.title}
                classTitle={cn('title-blue', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderCourseDetail cards={9} />
                ) : (
                    <CourseDataDetail data={courseData?.course_data} />
                )}
            </Card>
            <Card
                className={styles.card}
                title={'Unikatowe wyświetlenia'}
                classTitle={cn('title-green', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderCourseDetail cards={10} />
                ) : (
                    <CourseDetailViews data={courseData?.unique_views} />
                )}
            </Card>
            <Card
                className={styles.card}
                title={'Komentarze'}
                classTitle={cn('title-yellow', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderCourseDetail cards={10} />
                ) : (
                    <CourseDetailComments data={courseData?.comments} />
                )}
            </Card>
            <Card
                className={styles.card}
                title={'Reakcje'}
                classTitle={cn('title-purple', styles.title)}
            >
                {isLoading ? (
                    <SkeletonLoaderCourseDetail cards={10} />
                ) : (
                    <CourseDetailReactions data={courseData?.reactions} />
                )}
            </Card>
        </div>
    );
}