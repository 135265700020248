import React from 'react';
import styles from './Stats.module.sass';
import Icon from 'components/Icon';
import Card from 'components/Card';
import cn from 'classnames';

export default function Stats({ new_users, tests_taken, unique_users_logged_in, users_past_day }) {

    return (
        <>
            <Card
                className={styles.card}
                title='Statystyki ogólne'
                classTitle={cn('title-purple', styles.title)}
                classCardHead={styles.card_head}
            >
                <div className={styles.item}>
                    <Icon name={'profile-circle'} fill={'#2A85FF'} size={25} />
                    <div className={styles.item_tip}>Nowi użytkownicy:</div>
                    {new_users}
                </div>
                <div className={styles.item}>
                    <Icon name={'profile-circle'} fill={'#83BF6E'} size={25} />
                    <div className={styles.item_tip}>Wykonane testy osobowości:</div>
                    {tests_taken}
                </div>
                <div className={styles.item}>
                    <Icon name={'profile-circle'} fill={'#FF6A55'} size={25} />
                    <div className={styles.item_tip}>Unikatowi zalogowani użytkownicy:</div>
                    {unique_users_logged_in}
                </div>
                <div className={styles.item}>
                    <Icon name={'profile-circle'} fill={'#8E59FF'} size={25} />
                    <div className={styles.item_tip}>Użytkownicy zalogowani wczoraj:</div>
                    {users_past_day}
                </div>
            </Card>
        </>
    );
}