import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './Drafts.module.sass';
import Card from 'components/Card';
import Form from 'components/Form';
import Table from '../Table';

import Filters from 'components/Filters';
import Dropdown from 'components/Dropdown';
import Loader from '../Loader';
import FilterSettings from './FilterSettings';
import DeleteButton from './DeleteButton';

import {
  sortOptions,
  filterOptionsType,
  filterOptionsStatus,
  pageLimitOptions,
} from './utilities/options';
import devscntrAuth from 'api/Instance/devscntrAuth';
import { FilterOptionValue, FilterOptionName } from './utilities/names';

export default function Drafts(
  {
    selectedType,
    title,
    titleColor,
  }) {
  /* --------------------------------- SEARCH --------------------------------- */
  const [search, setSearch] = useState('');
  const prevSearch = useRef('');

  const searchHandler = value => {
    prevSearch.current = search;
    setSearch(value);
  };

  /* ------------------------------- PAGINATION ------------------------------- */
  const [currentPage, setCurrentPage] = useState(1);
  const prevPage = useRef(1);
  const [lastPage, setLastPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(pageLimitOptions[0]);

  /* --------------------------------- FILTERS -------------------------------- */
  const [filterValue, setFilterValue] = useState('all'); // for query
  const [filterStatusName, setFilterStatusName] = useState(
    filterOptionsStatus[0].name
  );
  const filterOptionsTypeNameArray = filterOptionsType.map(x => x.name);
  const filterOptionsStatusNameArray = filterOptionsStatus.map(x => x.name);

  /* --------------------------------- SORTING -------------------------------- */
  const [sortingName, setSortingName] = useState(sortOptions[0].name);
  const [sortingValue, setSortingValue] = useState(sortOptions[0].value);
  const sortOptionsNameArray = sortOptions.map(x => x.name);

  /* -------------------------------- FETCH API ------------------------------- */
  const [loadingPublications, setLoadingPublications] = useState(true);
  const [publicationsList, setPublicationsList] = useState([]);
  const [publicationsFailed, setPublicationsFailed] = useState();

  const havePublications = publicationsList.length > 0;
  const publicationsQuery = `?&page_size=${pageLimit}&page=${currentPage}&filter=${filterValue}&orderby=${sortingValue}${search && '&search='}${search}`;

  /* ------------------------------- CHECKBOXES ------------------------------- */
  const [checkedPublications, setCheckedPublications] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [itemsSelectedQuantity, setItemsSelectedQuantity] = useState(0);

  /* ---------------------------------- UTILS --------------------------------- */
  const [reRender, setReRender] = useState(0); // children can reRender this component by this state
  const reRenderHandler = () => setReRender(latest => latest + 1);

  const controller = new AbortController();
  const mountedRef = useRef(true); // if component is still mounted
  const isFirstFetch = useRef(true); // if this is the first time to fetch

  /* -------------------------------------------------------------------------- */
  /*                                   GENERAL                                  */
  /* -------------------------------------------------------------------------- */

  // CLEANUP FUNCTION
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // PAGINATION RESET
  useEffect(() => {
    setLoadingPublications(true);
    prevPage.current = currentPage;
    setCurrentPage(1);
  }, [sortingValue, filterValue, search, reRender]);

  useEffect(() => {
    if (!isFirstFetch) {
      setSearch(latest => {
        prevSearch.current = latest;
        return latest;
      });
    }
  }, [currentPage]);

  /* -------------------------------------------------------------------------- */
  /*                          FETCHING THE PUBLICATIONS                         */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    let delayDebounceFn;

    // MAIN FETCHING
    if (
      isFirstFetch.current ||
      (search === prevSearch.current && currentPage !== prevPage.current)
    ) {
      getPublications();
      isFirstFetch.current = false;
    }

    // DELAY FETCHING (for the searchbar)
    else {
      delayDebounceFn = setTimeout(() => {
        getPublications();
      }, 500);
    }

    return () => {
      controller.abort();
      clearTimeout(delayDebounceFn);
    };
  }, [pageLimit, sortingValue, filterValue, reRender, currentPage, search]);

  const getPublications = async () => {
    setPublicationsFailed(false);
    setLoadingPublications(true);

    try {
      const response = await devscntrAuth.get(
        `/publikacje/publication/v2/${publicationsQuery}`,
        {
          signal: controller.signal,
        }
      );
      setPublicationsList(response?.data?.data);
      // można to zapamiętać i zmieniać gdy się różni
      setLastPage(response?.data?.pages);
    } catch (err) {
      console.error(err);
      mountedRef.current && !havePublications && setPublicationsFailed(true);
    }

    mountedRef.current && setLoadingPublications(false);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   SORTING                                  */
  /* -------------------------------------------------------------------------- */

  // current sorting from the drop-down list
  useEffect(() => {
    const sortOptionID = sortOptions.findIndex(obj => obj.name === sortingName);
    setSortingValue(sortOptions[sortOptionID].value);
  }, [sortingName]);

  /* -------------------------------------------------------------------------- */
  /*                                   FILTERS                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    let typeValue;
    let statusValue;

    switch (selectedType) {
      case 'courses':
        typeValue = FilterOptionValue.COURSES;
        break;

      case 'jobs':
        typeValue = FilterOptionValue.JOBS;
        break;

      case 'posts':
        typeValue = FilterOptionValue.POSTS;
        break;

      default:
        typeValue = FilterOptionValue.ALL;
        break;
    }

    switch (filterStatusName) {
      case FilterOptionName.DRAFTS:
        statusValue = FilterOptionValue.DRAFTS;
        break;

      case FilterOptionName.PUBLISHED:
        statusValue = FilterOptionValue.PUBLISHED;
        break;

      case FilterOptionName.SCHEDULED:
        statusValue = FilterOptionValue.SCHEDULED;
        break;

      default:
        statusValue = FilterOptionValue.ALL;
        break;
    }

    // setting a proper name for query
    if (
      typeValue === FilterOptionValue.ALL &&
      statusValue === FilterOptionValue.ALL
    ) {
      setFilterValue(FilterOptionValue.ALL);
    } else if (
      typeValue === FilterOptionValue.ALL &&
      statusValue !== FilterOptionValue.ALL
    ) {
      setFilterValue(`${FilterOptionValue.ALL}%26${statusValue}`);
    } else if (
      typeValue !== FilterOptionValue.ALL &&
      statusValue === FilterOptionValue.ALL
    ) {
      setFilterValue(`${typeValue}`);
    } else {
      setFilterValue(`${typeValue}%26${statusValue}`);
    }
  }, [selectedType, filterStatusName]);

  const clearFiltersHandler = () => {
    setPageLimit(pageLimitOptions[0]);
    setFilterValue(FilterOptionValue.ALL);
    setFilterStatusName(filterOptionsStatus[0].name);
  };

  /* -------------------------------------------------------------------------- */
  /*                           MANAGING THE CHECKBOXES                          */
  /* -------------------------------------------------------------------------- */

  // when the list changes, need to uncheck the checkboxes
  useEffect(() => {
    if (isCheckAll) {
        setCheckedPublications([]);
        setIsCheckAll(false);
        setItemsSelectedQuantity(0);
    }
}, [publicationsList]);

  useEffect(() => {
    setItemsSelectedQuantity(checkedPublications.length);
  }, [checkedPublications]);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    setIsCheckAll(latest => {
      latest
        ? setCheckedPublications(publicationsList.map(x => x))
        : setCheckedPublications([]);
      return latest;
    });
  };

  const handleSelect = e => {
    const row = e.target.closest('.row');
    const rowId = +row.getAttribute('data-item-id');
    const rowType = row.getAttribute('data-item-type');
    const checked = e.target.checked;

    const currPublication = publicationsList.find(
      pub => pub.id === rowId && pub.type === rowType
    );

    // when clicking on a single checkbox unchecks the "all" checkbox
    setIsCheckAll(false);

    // if there is no such object yet in the array of objects (selected), it will enter it
    if (
      !checkedPublications.find(
        checked => checked.id === rowId && checked.type === rowType
      )
    ) {
      setCheckedPublications(latest => [...latest, currPublication]);
    }

    // if unchecked, the filter will leave those which are checked (the current object will not be there)
    if (!checked) {
      setCheckedPublications(latest =>
        latest.filter(obj => !(obj.id === rowId && obj.type === rowType))
      );
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                     DOM                                    */
  /* -------------------------------------------------------------------------- */
  console.log(publicationsList)
  return (
    <>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title={title}
        classTitle={cn(titleColor, styles.title)}
        head={
          <>
            {/* -------------------------------- SEARCHBAR ------------------------------- */}
            <Form
              className={styles.form}
              value={search}
              setValue={searchHandler}
              onSubmit={e => e.preventDefault()}
              placeholder='Szukaj...'
              type='text'
              name='search'
              icon='search'
            />
            <div className={styles.row}>
              <div className={cn(styles.column, styles.sorting)}>
                {/* ---------------------------------- SORT ---------------------------------- */}
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={sortingName}
                  setValue={setSortingName}
                  options={sortOptionsNameArray}
                />
              </div>
              <div className={styles.column}>
                {/* --------------------------------- FILTER --------------------------------- */}
                <Filters className={styles.filter} title='Filtrowanie publikacji'>
                  <FilterSettings
                    statusName={filterStatusName}
                    setStatusName={setFilterStatusName}
                    typeOptions={filterOptionsTypeNameArray}
                    statusOptions={filterOptionsStatusNameArray}
                    clearFilters={clearFiltersHandler}
                    pageLimitValue={pageLimit}
                    setPageLimit={setPageLimit}
                    pageLimitOptions={pageLimitOptions}
                    isPagination={true}
                    isStatus={true}
                    isReset={true}
                  />
                </Filters>
                <DeleteButton
                  quantity={itemsSelectedQuantity}
                  disabled={!itemsSelectedQuantity}
                  checkedPublications={checkedPublications}
                  setReRender={reRenderHandler}
                />
              </div>
            </div>
          </>
        }
      >
        {/* ---------------------------------- TABLE --------------------------------- */}
        <div className={styles.wrapper}>
          {!loadingPublications && havePublications && (
            <Table
              items={publicationsList}
              checkedPublications={checkedPublications}
              isCheckAll={isCheckAll}
              handleSelect={handleSelect}
              handleSelectAll={handleSelectAll}
              setReRender={reRenderHandler}
            />
          )}
          {loadingPublications && <Loader cards={5} />}
          {!loadingPublications && !havePublications && !publicationsFailed && (
            <p className={styles.info}>Brak publikacji</p>
          )}
          {!loadingPublications && !havePublications && publicationsFailed && (
            <p className={styles.info}>
              Nie udało się pobrać twoich publikacji. Spróbuj ponownie później.
            </p>
          )}
        </div>

        {/* ------------------------------- PAGINATION ------------------------------- */}
        {!loadingPublications && havePublications && (
          <div className={styles.foot}>
            {currentPage > 1 && (
              <button
                className={cn('button-stroke button-small', styles.button)}
                style={{ marginRight: '12px' }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCurrentPage(prevState => {
                    prevPage.current = currentPage;
                    return --prevState;
                  });
                }}
              >
                Poprzednia
              </button>
            )}
            {/* --------------------------- 3 DOTS AFTER FIRST --------------------------- */}
            {currentPage > 2 && (
              <>
                <button
                  className={styles.pagination_numbers}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPage(() => {
                      prevPage.current = currentPage;
                      return 1;
                    });
                  }}
                >
                  {1}
                </button>
                ...
              </>
            )}
            {/* ----------------------- NUMBERS (PREV, CURR, NEXT) ----------------------- */}
            {currentPage > 1 && (
              <button
                className={styles.pagination_numbers}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCurrentPage(prevState => {
                    prevPage.current = currentPage;
                    return --prevState;
                  });
                }}
              >
                {currentPage - 1}
              </button>
            )}
            {currentPage !== 0 && (
              <button
                className={styles.pagination_numbers}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {currentPage}
              </button>
            )}
            {currentPage < lastPage && (
              <button
                className={styles.pagination_numbers}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCurrentPage(prevState => {
                    prevPage.current = currentPage;
                    return ++prevState;
                  });
                }}
              >
                {currentPage + 1}
              </button>
            )}
            {/* --------------------------- 3 DOTS BEFORE LAST --------------------------- */}
            {currentPage < lastPage - 1 && (
              <>
                ...
                <button
                  className={styles.pagination_numbers}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPage(() => {
                      prevPage.current = currentPage;
                      return lastPage;
                    });
                  }}
                >
                  {lastPage}
                </button>
              </>
            )}
            {/* ------------------------------- NEXT BUTTON ------------------------------ */}
            {currentPage != lastPage && (
              <button
                className={cn('button-stroke button-small', styles.button)}
                style={{ marginLeft: '12px' }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setCurrentPage(prevState => ++prevState);
                }}
              >
                Następna
              </button>
            )}
          </div>
        )}
      </Card>
    </>
  );
}