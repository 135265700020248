import React from 'react';
import styles from './ItemJZWIT.module.sass';
import cn from 'classnames';
import Placement from '../../../../../components/Placement';
import { Link } from 'react-router-dom';

export default function ItemJZWIT({ item, index }) {

    const currentUrl = (slug) => {
        const newPath = slug.split('_')
        const newUrl = newPath.join('/')
        return newUrl
    }

    return (
        <Link 
        to={`/${currentUrl(item?.slug)}`}
        className={cn(styles.container)}
        >
            <div className={styles.placement}>
                <Placement placement={index} />
                <span>{item?.object || 'Brak nazwy'}</span>
            </div>
            <div className={styles.activity}>
                <div className={styles.activity_text}>
                    Wyświetlenia:
                </div>
                <span>{item?.views || 'Brak wyniku'}</span>
            </div>
        </Link>
    );
}