import React from 'react';
import styles from './Posts.module.sass';
import Icon from 'components/Icon';
import Card from 'components/Card';
import cn from 'classnames';

export default function Posts({ data }) {

    return (
        <>
            <Card
                className={styles.card}
                title='Statystyki postów'
                classTitle={cn('title-purple', styles.title)}
                classCardHead={styles.card_head}
            >
                <div className={styles.item}>
                    <Icon name={'store'} fill={'#6F767E'} size={25} />
                    <div className={styles.item_tip}>Ilość postów: </div>
                    {data?.count}
                </div>
                <div className={styles.item}>
                    <Icon name={'message'} fill={'#6F767E'} size={25} />
                    <div className={styles.item_tip}>Ilość komentarzy:</div>
                    {data?.comments_count}
                </div>
                <div className={styles.item}>
                    <Icon name={'thumbs-up-fill'} fill={'#6F767E'} size={25} viewBox='0 0 24 24' />
                    <div className={styles.item_tip}>Ilość lików:</div>
                    {data?.likes_count}
                </div>
                <div className={styles.item}>
                    <Icon name={'thumbs-down-fill'} fill={'#6F767E'} size={25} viewBox='0 0 24 24' />
                    <div className={styles.item_tip}>Ilość dislików:</div>
                    {data?.dislikes_count}
                </div>

            </Card>
        </>
    );
}