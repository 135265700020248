import React from 'react';
import styles from './CourseDetailViews.module.sass';

export default function CourseDetailViews({ data }) {

    return (
        <div className={styles.container}>
            {data?.map((item, index) => (
                <div className={styles.item} key={index}>
                    <div className={styles.text}>
                        <span>Użytkownik </span>{item?.user ? item?.user : 'Niezalogowany'}
                        <span> o IP </span>{item?.ip}
                        <span> w dniu </span>{item?.date}
                        <span> o godzinie </span>{item?.time}
                    </div>
                </div>
            ))}
        </div>
    );
}