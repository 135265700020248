import React, { useEffect, useState } from 'react';
import styles from './Admin.module.sass';
import { Link, Route, Switch } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Users from './Users';
import UserModal from './UserModal';
import Tags from './Tags';
import Icon from 'components/Icon';
import UserDetail from './Users/UserDetail';
import TagDetail from './Tags/TagDetail';
import Dashboard from './Dashboard';
import ReportedBugs from './Reports/ReportedBugs';
import ReportedContent from './Reports/ReportedContent';
import Levels from './Settings/Levels';
import Points from './Settings/Points';
import Badges from './Settings/Badges';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import cn from 'classnames';
import Dropdown from 'components/Dropdown';
import Posts from './Posts';
import PostDetail from './Posts/PostDetail';
import Courses from './Courses';
import CourseDetail from './Courses/CourseDetail';

const navigation = [
  {
    title: 'Dashboard',
    icon: 'lightning',
    url: '/admin',
  },
  {
    title: 'Kursy',
    icon: 'store',
    url: '/admin/courses',
  },
  {
    title: 'Wpisy',
    icon: 'message',
    url: '/admin/posts',
  },
  {
    title: 'Użytkownicy',
    icon: 'profile-circle',
    url: '/admin/users',
  },
  {
    title: 'Tagi',
    icon: 'ticket',
    url: '/admin/tags',
  },
  {
    title: 'Zgłoszone błędy',
    icon: 'bug',
    url: '/admin/support/bug-reports',
  },
  {
    title: 'Zgłoszone treści',
    icon: 'ticket',
    url: '/admin/support/content-reports',
  },
  {
    title: 'Ordery',
    icon: 'ticket',
    url: '/admin/settings/badges',
  },
  {
    title: 'Zarządzanie poziomami',
    icon: 'ticket',
    url: '/admin/settings/levels',
  },
  {
    title: 'Zarządzanie XP',
    icon: 'ticket',
    url: '/admin/settings/points',
  },
];

const mobile_navigation = navigation.map((item) => { return item?.title })

const Admin = ({ children }) => {
  const authCtx = useAuth();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname

  function getCurrentWidth() {
    return {
      width: window.innerWidth,
    };
  }

  const [screenWidth, setScreenWidth] = useState(getCurrentWidth());

  const [navigationValue, setNavigationValue] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    navigation.map((item) => {
      if (item?.title === navigationValue) {
        history.push(item?.url)
      }
    })
  }, [navigationValue]);

  useEffect(() => {
    navigation.map((item) => {
      if (item?.url === pathname) {
        setNavigationValue(item?.title)
      }
    })
  }, [pathname]);

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentWidth());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenWidth]);

  if (!authCtx.isAdmin) return null;

  return (
    <>
      <UserModal />
      <div className={styles.row}>
        <div className={styles.col}>
          {screenWidth.width > 900 ? (
            <div className={styles.nav}>
              {navigation.map((item, index) => (
                <Link
                  to={item.url}
                  className={cn(styles.item, {
                    [styles.active]: item.url === pathname,
                  })}
                  key={`admin_nav_${index}`}
                >
                  <Icon name={item.icon} size={20} />
                  {item.title}
                </Link>
              ))}
            </div>
          ) : (
            <div className={styles.mobile_nav}>
              <Dropdown options={mobile_navigation} value={navigationValue} setValue={setNavigationValue} />
            </div>
          )}
          <div className={styles.content}>{children}</div>

          <Switch>
            <Route exact path='/admin'>
              <Dashboard />
            </Route>
            <Route exact path='/admin/courses'>
              <Courses />
            </Route>
            <Route exact path='/admin/courses/:id'>
              <CourseDetail />
            </Route>
            <Route exact path='/admin/posts'>
              <Posts />
            </Route>
            <Route exact path='/admin/posts/:id'>
              <PostDetail />
            </Route>
            <Route exact path='/admin/users'>
              <Users />
            </Route>
            <Route exact path='/admin/users/:id'>
              <UserDetail />
            </Route>
            <Route exact path='/admin/tags'>
              <Tags />
            </Route>
            <Route exact path='/admin/tags/:id'>
              <TagDetail />
            </Route>
            <Route exact path='/admin/support/bug-reports'>
              <ReportedBugs />
            </Route>
            <Route exact path='/admin/support/content-reports'>
              <ReportedContent />
            </Route>
            <Route exact path='/admin/settings/badges'>
              <Badges />
            </Route>
            <Route exact path='/admin/settings/levels'>
              <Levels />
            </Route>
            <Route exact path='/admin/settings/points'>
              <Points />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};
export default Admin;
