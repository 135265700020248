import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './User.module.sass';
import Icon from '../../Icon';
import ProfileAvatar from '../../ProfileAvatar';
import useAuth from '../../../hooks/useAuth';
import UserLevelBar from '../UserLevelBar';
import Level from './Level';

const User = ({ className }) => {
  const authCtx = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const [userLevel, setUserLevel] = useState(null);

  useEffect(() => {
    if (authCtx.levelData) {
      setUserLevel(authCtx.levelData);
    }
  }, [authCtx.levelData]);

  const items = [
    {
      menu: [
        {
          title: 'Profil',
          icon: 'profile-circle',
          url: '/profile/' + authCtx.userName,
        },
        authCtx.isAuthor
          ? {
              title: 'Panel twórcy',
              icon: 'bar_chart',
              url: '/publications/' + authCtx.userID,
            }
          : '',
        authCtx.isAdmin
          ? {
              title: 'Narzędzia administratora',
              icon: 'leaderboard',
              url: '/admin',
            }
          : '',
        {
          title: 'Moje konto',
          icon: 'calendar',
          url: '/account/cv',
        },
        {
          title: 'Ustawienia konta',
          icon: 'setting',
          url: '/settings/profile-data',
        },
      ],
    },
    {
      menu: [
        {
          title: 'Wyloguj się',
          icon: 'arrow-right',
          onClick: () => {
            history.push('/logout', {
              from: location.pathname + location.search,
            });
          },
          color: true,
        },
      ],
    },
  ];
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={styles.container}>
        <div
          className={cn(styles.user, className, { [styles.active]: visible })}
        >
          <ProfileAvatar
            image={authCtx.userImg}
            level={userLevel?.level || 0}
            onClick={() => setVisible(!visible)}
            small
            itsMe
          />
          <div className={styles.body}>
            <h3 className={styles.username}>{authCtx.userName}</h3>
            {userLevel && <Level data={userLevel} />}

            {items.map((item, index) => (
              <div className={styles.menu} key={index}>
                {item.menu.map(
                  (x, index) =>
                    x.title &&
                    (x.url ? (
                      <NavLink
                        className={cn(styles.item, { [styles.color]: x.color })}
                        activeClassName={styles.active}
                        to={x.url}
                        onClick={() => setVisible(false)}
                        key={index}
                      >
                        {x.icon && <Icon name={x.icon} size='24' />}
                        {x.title}
                      </NavLink>
                    ) : (
                      <button
                        className={cn(styles.item, { [styles.color]: x.color })}
                        onClick={() => {
                          if (x?.onClick) x.onClick();
                          setVisible(false);
                        }}
                        key={index}
                      >
                        {x.icon && <Icon name={x.icon} size='24' />}
                        {x.title}
                      </button>
                    ))
                )}
              </div>
            ))}
          </div>
        </div>
        {userLevel && (
          <UserLevelBar
            min={userLevel.thisLevelXp}
            max={userLevel.xpToNextLevel}
            current={userLevel.currentXp}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default User;
