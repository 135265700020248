export const contentStream = {
  get: '/streamv2/stream-v3/',
};

export const postsEndpoints = {
  // list and detail
  show: '/posts/article/show-post/',
  userPosts: '/posts/article/user-post/',
  // create and delete
  create: '/posts/article/create-post/',
  update: '/posts/article/create-post/',
  delete: '/posts/article/create-post/',
  // reactions
  reaction: '/posts/add-reaction/',
  rating: '/posts/article/rating-post/',
  // comments
  showComments: '/posts/article/comment-anon/',
  createComment: '/posts/article/comment/',
  reactionComment: '/posts/article/comment-emote/',
  // tests
  showTest: '/posts/article/show-test/',
  createTest: '/posts/article/test-create/create/',
  submitTest: '/posts/article/test-submit/submit/',
  // views
  externalLinkCount: '/posts/article/external-views/',
  idAndSlug: '/posts/article/id-and-slug/',
};

export const coursesEndpoints = {
  // list and detail
  show: '/course/show-course/',
  userCourses: '/course/user-course/',
  // create and delete
  create: '/course/course-actions/',
  update: '/course/course-actions/',
  delete: '/course/course-actions/',
  // reactions
  reaction: '/course/course-emotion/',
  rating: '/course/course-rate/',
  review: '/course/course-review/',
  // comments
  showComments: '/course/course-comment-anon/',
  createComment: '/course/course-comment/',
  reactionComment: '/course/course-comment-emote/',
  // views
  externalLinkCount: '/course/external-views/',
  idAndSlug: '/course/id-and-slug/',
};

export const jobsEndpoints = {
  // list and detail
  show: '/jobs/show-job/',
  userJobs: '/jobs/user-job/',
  // create and delete
  create: '/jobs/job-create/',
  update: '/jobs/job-create/',
  delete: '/jobs/job-create/',
  salariesCategory: 'jobs/average-salary/',
  // views
  externalLinkCount: '/jobs/external-views/',
  // filters
  seniorityLevels: '/jobs/seniority-levels/',
  idAndSlug: '/jobs/id-and-slug/',
};

export const jzwitEndpoints = {
  displayTech: '/jakzaczacwit/jzwit/displaytech/',
  createTech: '/jakzaczacwit/jzwit/admincreatetech/',
  delete: '/jakzaczacwit/jzwit/admincreatetech/',
  displayComments: '/jakzaczacwit/jzwit/displaycomments/',
  createComment: '/jakzaczacwit/jzwit/createcomment/',
  commentEmotion: '/jakzaczacwit/jzwit/commentemotion/',
};

export const jzwitv2Endpoints = {
  displayTech: '/jakzaczacwit/v2/object/',
  createTech: '/jakzaczacwit/v2/object/',
  delete: '/jakzaczacwit/jzwit/admincreatetech/',
  addComment: '/jakzaczacwit/v2/add-comment/',
  showComments: '/jakzaczacwit/v2/display-comment/',
  commentEmotion: '/jakzaczacwit/v2/emote-comment/',
};

export const levelsEndpoints = {
  showList: '/levels/',
  userLevelGroupHistory: '/levels/groupHistory',
  userLevelHistory: '/levels/history',
  userLevel: '/levels/xpUser',
  userRank: '/levels/',
};

export const tagsEndpoints = {
  show: '/misc/tags-limited/',
  showAll: '/misc/tags-limited/',
  create: '/misc/tags/',
};

export const earningsEndpoints = {
  earnings: '/misc/earning-stats/',
  categories: '/misc/categories/',
};

export const miscEndpoints = {
  search: '/search/search/',
  searchCombined: 'search/prettysearch/',
  newestComments: '/misc/newest-comments/',
  newestCourses: '/misc/newest-courses/',
  recommendedPosts: '/misc/recommended/',
};

export const accountsEndpoints = {
  admin: {
    tags: '/accounts/admin/tags/',
    users: '/accounts/admin/users/',
  },
  company: {
    show: '/accounts/company/show/',
    create: '/accounts/company/create/',
  },
  auth: {
    //token
    refreshToken: '/accounts/token/refresh',
    // login
    login: '/accounts/login',
    loginGoogle: '/google/login/',
    phoneVerification: '/accounts/login/phone-verifications',
    resendPhoneVerification: '/accounts/login/phone-code-resend',
    // registration
    register: '/accounts/registration',
    registerActivate: '/accounts/registration/activate',
    registerResendActivation: '/accounts/email/resend-verification',
    registerCheckFiled: '/accounts/registration/check-field-taken',
    sendPasswordReset: '/accounts/password/reset/send-email',
    passwordReset: '/accounts/password/reset',
  },
  userAuthData: {
    // phone number
    changePhone: '/accounts/phone/change-requests',
    // email
    changeEmail: '/accounts/email/change-requests',
    changeEmailAccept: '/accounts/email/update-requests',
    // password
    changePassword: '/accounts/password',
    resetPassword: '/accounts/password/reset',
    sendResetPasswordEmail: '/accounts/password/reset/send-email',
  },
  // user's data
  userData: {
    myAccount: '/accounts/me',
    userInfo: '/accounts/me/user-info/',
    profileImage: '/accounts/me/image-upload',
    backgroundImage: '/accounts/me/background-image-upload',
    followUser: '/accounts/me/follow-user/',
    groups: '/accounts/me/managegroup/',
    tagPreferences: '/accounts/me/tag-preferences/',
    levelHistory: '/accounts/me/xphistory/',
  },
  userContent: {
    userPins: '/accounts/user-pins/',
    followUserPosts: '/accounts/me/follow-user-posts/',
    followedContent: '/accounts/me/followed-content/',
    userBlocked: '/accounts/me/blockedusers/',
  },
  // notifications
  notifications: {
    show: '/accounts/me/notification/',
    delete: '/accounts/me/notification-delete/',
  },
  documents: {
    CV: {
      show: '/accounts/me/CV/',
      create: '/accounts/me/CV/',
      delete: '/accounts/me/CV/',
    },
    jobApplications: {
      show: '/jobs/job-applicate/',
      create: '/jobs/job-applicate/',
      delete: '/jobs/job-applicate/',
    },
  },
};

export const adminEndpoints = {
  dashboard:
  {
    dashboard: 'accounts/admin/dashboard/',
    courses_stats: 'accounts/admin/course-stats/',
    posts_stats: 'accounts/admin/posts-stats/',
  },
  users: {
    show: '/accounts/admin/users/',
    update: '/accounts/admin/users/',
    publications: '/accounts/admin/publications/',
    levels: {
      addPoints: '/accounts/admin/levels/',
    },
    ban: '/accounts/admin/banuser/',
    unban: '/accounts/admin/banuser/',
    bannedUsers: '/accounts/admin/usersbanslist/',
  },
  usercontentstats: {
    get: '/accounts/admin/usercontentstats/',
  },
  company: {
    update: '/accounts/admin/companies/',
  },
  tags: {
    show: '/accounts/admin/tags/',
  },
  posts: {
    update: '/accounts/admin/posts/',
    delete: '/accounts/admin/posts/',
  },
  courses: {
    update: '/accounts/admin/courses/',
    delete: '/accounts/admin/courses/',
  },
  jobs: {
    update: '/accounts/admin/jobs/',
    delete: '/accounts/admin/jobs/',
  },
  jzwit: {
    update: '/jakzaczacwit/jzwit/admincreatetech/',
    delete: '/jakzaczacwit/jzwit/admincreatetech/',
  },
  settings: {
    levels: '/accounts/admin/levels/',
    badges: '/accounts/admin/orders/',
    points: '/accounts/admin/actionpoints/',
  },
};

export const supportEndpoints = {
  reportBug: {
    send: '/accounts/reports/bugs/',
    show: '/accounts/admin/bugreports/',
    update: '/accounts/admin/bugreports/',
  },
  reportContent: {
    posts: {
      article: '/posts/article/report-post/',
      comment: '/posts/article/report-post-comment/',
    },
    courses: {
      article: '/course/report-course/',
      comment: '/course/report-course-comment/',
    },
    jobs: {
      article: '/jobs/job-report/',
    },
    send: '/accounts/reports/content/',
    show: '/accounts/admin/contentreports/',
    update: '/accounts/admin/contentreports/',
  },
  reportUser: '/accounts/reports/users/',
};

export const b5TestEndpoints = {
  save: '/accounts/me/personality/',
  get: '/accounts/me/personality/',
  calculateSuggestions: '/accounts/me/personality-paths/',
  shareSettings: '/accounts/me/personality-public/',
};
