import React from 'react';
import styles from './Sidebar.module.sass';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';
import Icon from '../Icon';
import Theme from '../Theme';
import Dropdown from './Dropdown';
import Image from '../Image';
import useAuth from 'hooks/useAuth';
import { socialMedia } from 'data/dataArrays';
import { getEnvName } from 'utils';
import NavigationLink from './NavigationLink';

const Sidebar = ({ className, visible, setVisible, onClose }) => {
  const authCtx = useAuth();
  const isAdmin = authCtx.isAdmin;

  const navigation = [
    {
      title: 'Kursy',
      icon: 'store',
      slug: 'kursy',
      url: '/courses',
      alternativeUrl: '/',
    },
    {
      title: 'Wpisy',
      icon: 'message',
      url: '/posts',
    },
    getEnvName() !== 'prod' && {
      title: 'Oferty pracy',
      icon: 'job',
      slug: 'praca',
      url: '/jobs?sortby=newest',
    },
    {
      title: 'Oferty staży',
      icon: 'diamond',
      slug: 'staz',
      url: '/internships?sortby=newest',
    },
    authCtx.isLoggedIn && {
      title: 'Przypinki',
      icon: 'heart',
      add: true,
      addgroups: true,

      dropdown: [
        {
          title: 'Moje przypinki',
          url: '/',
          groups: true,
          // mapowanie nowo dodanych grup
        },
      ],
    },
    authCtx.isLoggedIn && {
      title: 'Subskrypcje',
      icon: 'profile-circle',
      dropdown: [
        {
          title: 'Pokaż wszystkie',
          url: '/subscriptions',
          subs: true,
        },
      ],
    },
    {
      title: 'Jak zacząć w IT',
      icon: 'desktop',
      slug: 'IT',
      url: '/jak-zaczac-w-it',
    },
    {
      title: 'Zarobki w IT',
      icon: 'bar_chart',
      slug: 'zarobki_w_it',
      url: '/zarobki-w-it',
    },
    {
      title: 'Zbadaj osobowość',
      icon: 'leaderboard',
      slug: 'big_five',
      url: '/big-five',
    },
  ];

  return (
    <nav
      className={cn(styles.sidebar, className, { [styles.active]: visible })}
    >
      <button className={styles.close} onClick={onClose}>
        <Icon name='close' size='24' />
      </button>
      <Link className={styles.logo} to='/'>
        <Image
          className={styles.pic}
          src='/images/logo-light.svg'
          srcDark='/images/logo-dark.svg'
          alt='itCNTR'
        />
        <h1 className={styles.logotitle}>it CNTR</h1>
      </Link>
      <ul className={styles.menu}>
        {navigation.map(
          (x, index) =>
            x?.title &&
            (x.url ? (
              <NavigationLink
                visibleSidebar={visible}
                key={index}
                item={x}
                className={styles.link}
                onClick={() => {
                  if (window.innerWidth < 768) onClose();
                  window.scrollTo(0, 0);
                }}
              />
            ) : (
              <li key={index}>
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              </li>
            ))
        )}
      </ul>
      <section className={styles.socialmedia}>
        <NavLink
          to={'/support/contact'}
          className={styles.item}
          activeClassName={styles.active}
          onClick={() => {
            if (window.innerWidth < 768) onClose();
            window.scrollTo(0, 0);
          }}
          exact
        >
          <Icon name={'mail'} size='24' />
        </NavLink>
        {socialMedia.map((item, index) => (
          <a
            href={item.url}
            target='_blank'
            className={styles.link}
            key={`social_media_${index}`}
            onClick={() => {
              if (window.innerWidth < 768) onClose();
              window.scrollTo(0, 0);
            }}
            rel='noreferrer'
          >
            <Icon name={item.icon} size='24' />
          </a>
        ))}
      </section>

      <div className={styles.foot}>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <div className={styles.burger}></div>
          Zwiń menu
        </button>
        <Theme className={styles.theme} visibleSidebar={visible} />
        <div className={styles.footer_info}>
          <div className={styles.policy_links}>
            <Link to='/privacy'>Polityka prywatności</Link> i{' '}
            <Link to='/statute'>Regulamin</Link>
          </div>
          <div className={styles.version}>
            BETA {process.env.REACT_APP_GITVERSION_FULLSEMVER}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
