import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import styles from './DatePicker.module.sass';
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';
import cn from 'classnames';

const DatePicker = (
    {
        value,
        startingDate,
        label,
        yearRangeStart = 2020,
        className,
    }) => {
    const [startDate, setStartDate] = useState('');

    useEffect(() => {
        if (startingDate) {
            setStartDate(new Date(startingDate));
        }
    }, [])

    const years = range(yearRangeStart, getYear(new Date()) + 10, 1);
    const months = [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień",
    ];

    const dateSubmit = (date) => {
        setStartDate(date);
    }

    useEffect(() => {
        value(startDate)
    }, [startDate])

    

    return (
        <div className={cn(styles.container, className && className)}>
            {label && (
                <div className={styles.label}>
                    {label}{' '}
                </div>
            )}
            <div className={styles.date_picker}>
                <div className={styles.calendar_icon}>
                    <Icon name="calendar" size="20" fill='gray' />
                </div>
                <ReactDatePicker
                    dateFormat="dd/MM/yyyy"
                    showIcon
                    onKeyDown={(e) => { e.preventDefault(); }}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div className={styles.header}>
                            <div className={styles.data_container}>
                                <Dropdown
                                    value={getYear(date)}
                                    options={years}
                                    setValue={changeYear}
                                    classDropdown={styles.header_select}
                                    classDropdownHead={styles.select_head}
                                />
                                <Dropdown
                                    value={months[getMonth(date)]}
                                    options={months}
                                    setValue={(e) => changeMonth(months.indexOf(e))}
                                    classDropdown={styles.header_select}
                                    classDropdownHead={styles.select_head}
                                />
                            </div>
                            <button className={styles.header_button} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <Icon name="arrow-next" size="20" fill='gray' transform='rotate(180)' />
                            </button>
                            <button className={styles.header_button} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <Icon name="arrow-next" size="20" fill='gray' />
                            </button>
                        </div>
                    )}
                    isClearable
                    selected={startDate}
                    onChange={(date) => dateSubmit(date)}
                    placeholderText="Podaj datę!"
                    calendarClassName={styles.calendar}
                />
            </div>
        </div>
    );

};


export default DatePicker;