import React, { useState } from 'react';
import styles from './ItemRestricted.module.sass';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { appPaths } from 'data/appPaths';
import { currencyList, jobContractType } from 'data/dataArrays';
import Tags from 'components/MiniItemsLayout/Tags';
import MoreOptions from 'components/MiniItemsLayout/MoreOptions';
import useAuth from 'hooks/useAuth';
import { showLoginModal } from 'utils/showLoginModal';
import ModalPin from 'components/ModalPin';
import Thumbnail from 'components/MiniItemsLayout/Thumbnail';

const currencyCode = currencyList[0].code;

const ItemRestricted = ({
  className,
  article,
  onDelete,
  hideActions = false,
}) => {
  // UTILS------------------------------------------------------------------------------UTILS

  const authCtx = useAuth();

  // UTILS------------------------------------------------------------------------------UTILS
  // DATE FORMAT------------------------------------------------------------------DATE FORMAT

  let date;
  if (article?.created_at) {
    try {
      date = new Date(article?.created_at).toLocaleDateString();
    } catch (e) {
      date = '';
    }
  }

  // DATE FORMAT------------------------------------------------------------------DATE FORMAT
  // PINS--------------------------------------------------------------------------------PINS

  const [pinState, setPinState] = useState(article?.pinned);
  const [StarModalVisible, setStarModalVisible] = useState(false);
  const location = useLocation();

  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === '/pins') {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };

  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setStarModalVisible(true);
  };

  // PINS--------------------------------------------------------------------------------PINS

  return (
    <div className={cn(styles.container, { [className]: className })}>
      {/* <----- !!!! MODALPIN !!!! -----> */}

      {(StarModalVisible || redirectedUser || unpinPost) && (
        <ModalPin
          visible={StarModalVisible}
          onClose={() => setStarModalVisible(false)}
          article={article}
          setPinState={setPinState}
          redirectedUser={redirectedUser}
          setRedirectedUser={setRedirectedUser}
          unpinPost={unpinPost}
          setunpinPost={setunpinPost}
          pinUrl={'/jobs/user-job/'}
          idName={'job_id'}
        />
      )}
      <div className={styles.row}>
        <Link
          to={`${appPaths.jobs.detail}/${article.id}`}
          className={styles.title}
        >
          {article?.title || 'Brak tytułu'}
        </Link>

        <div className={styles.actions}>
          <button
            className={styles.pin_btn}
            onClick={pinState ? unpinHandler : pinHandler}
          >
            <img
              src={
                pinState
                  ? '/images/Icons/heart/filled-color.svg'
                  : '/images/Icons/heart/stroke.svg'
              }
              alt='favourite'
            />
          </button>

          <MoreOptions
            item={article}
            authorId={article?.author_id || null}
            onDelete={onDelete}
            type='job'
          />
        </div>
      </div>

      {/* <Tags
        className={styles.tags}
        tags={article?.tags?.sort((a, b) => b.tags_count - a.tags_count)}
        baseLink='/jobs'
      /> */}

      <div className={styles.row}>
        <div className={styles.salary}>
          {article?.salary?.length < 1 && <p>Brak podanej pensji</p>}
          {article?.salary?.length > 0 && (
            <>
              <p>{jobContractType[article?.salary[0]?.type - 1]}: </p>
              {article?.salary[0].type == '1'
                ? `${parseFloat(
                    article?.salary[0]?.hour_wage
                  ).toLocaleString()} ${currencyCode}/h`
                : `${parseFloat(
                    article?.salary[0]?.salary_from
                  ).toLocaleString()} ${currencyCode} - ${parseFloat(
                    article?.salary[0]?.salary_to
                  ).toLocaleString()} ${currencyCode}`}
            </>
          )}
        </div>
        <div className={styles.date}>{date}</div>
      </div>
    </div>
  );
};

export default ItemRestricted;
