import style from './SkeletonLoaderStats.module.sass';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Card from 'components/Card';
import cn from 'classnames';

export default function SkeletonLoaderStats({ cards, title }) {

  const list = Array(cards).fill(0)

  return (
    <Card
      className={style.card}
      title={title}
      classTitle={cn('title-purple', style.title)}
      classCardHead={style.card_head}
    >
      {list.map((_,index) => (
        <SkeletonTheme key={index}>
          <div className={style.skeleton} key={index}>
            <div className={style.item} >
              <Skeleton height={25} borderRadius={8} />
            </div>
          </div>
        </SkeletonTheme>
      ))}
    </Card>
  )
}
